import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import { toPx } from "utils/strings";
import { isBrowserIE11 } from "utils/styles";

import Link from "components/Link";
import Icon from "components/Icon";
import Text from "components/Text";

// HINT: Before you change something here open `yarn storybook` and use this page:
// http://localhost:9001/?selectedKind=Button&selectedStory=Theming&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
// Otherwise you will break something with 99% chance of doing so!

export const StyledButtonIconLeft = styled(Icon)(
  {
    flex: "none",
    height: 18
  },
  props => ({
    marginRight:
      props.buttonSize == "tiny"
        ? 0
        : `${toPx(props.iconMargin || props.theme.spaceQuartered)}`
  })
);

export const StyledButtonIconRight = styled(Icon)(
  {
    flex: "none",
    height: 18
  },
  props => ({
    marginLeft:
      props.buttonSize == "tiny"
        ? 0
        : `${toPx(props.iconMargin || props.theme.spaceQuartered)}`
  })
);

export const StyledButtonContent = styled("span")({
  display: "flex",
  flexBasis: "100%",
  alignItems: "center"
});

export const StyledButtonText = styled("span")(
  props =>
    props.forceButtonTextShrink && {
      flex: "0"
    },
  // here comes a very pretty condition that checks if we are having IE 11
  // IE 11 doesn't know jack shit about flex-base, so we can't do flex: 0
  // unless you know how?
  () =>
    isBrowserIE11() && {
      flex: "0 1 auto"
    }
);

export const StyledButtonSubline = styled(Text)({
  flexBasis: "100%",
  maxWidth: "100%"
});

export const StyledButton = styled(Link)(
  {
    display: "inline-block",
    fontWeight: "700",
    letterSpacing: "0.05em",
    textDecoration: "none",
    fontSize: "18px",
    textTransform: "none",
    width: "100%",
    verticalAlign: "top",
    transition: "all .1s linear",

    [media("tablet")]: {
      width: "auto"
    }
  },
  props =>
    props.iconAnimate && {
      svg: {
        transition: "transform .2s ease-in-out"
      },

      "&:hover,  &:active": {
        color: "initial",
        // Actually referencing the styled component didn't work...
        svg: {
          transition: "transform .15s .1s ease-in-out",
          transform: `translateX(${
            props.iconLeft
              ? props.buttonSize == "tiny"
                ? "-5px"
                : "-15px"
              : props.buttonSize == "tiny"
              ? "5px"
              : "15px"
          })`
        }
      }
    },
  props => ({
    fontFamily: props.theme.fontPrimary,
    textAlign: props.textAlign,
    margin: props.margin,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: props.theme.borderRadiusPrimary,
    padding: `calc(${toPx(props.theme.spaceThirds)} - 3px) ${toPx(
      props.theme.space
    )}`,

    [media("tablet")]: {
      marginRight: toPx(props.theme.spaceThird)
    },

    [media("desktop")]: {
      padding: `calc(${toPx(props.theme.spaceThirds)} - 3px) ${toPx(
        props.theme.spaceDouble
      )}`
    }
  }),
  props =>
    props.theme.name === "default" && {
      color: props.filled
        ? props.theme.buttonDefaultColorHover
        : props.theme.buttonDefaultColor,
      backgroundColor: props.filled
        ? props.theme.buttonDefaultBackgroundHover
        : props.theme.buttonDefaultBackground,
      borderColor: props.theme.buttonDefaultBorderColor,

      "&:hover,  &:active": {
        color: props.theme.buttonDefaultColorHover,
        backgroundColor: props.theme.buttonDefaultBackgroundHover,
        borderColor: props.theme.buttonDefaultBorderColorHover
      }
    },
  props =>
    props.theme.name === "alternative" && {
      color: props.theme.buttonAlternativeColor,
      backgroundColor: props.theme.buttonAlternativeBackground,
      borderColor: props.theme.buttonAlternativeBorderColor,

      "&:hover, &:focus, &:active": {
        color: props.theme.buttonAlternativeColorHover,
        backgroundColor: props.theme.buttonAlternativeBackgroundHover,
        borderColor: props.theme.buttonAlternativeBorderColorHover
      }
    },
  props =>
    props.themeName === "green" && {
      color: props.filled
        ? props.theme.buttonDefaultColor
        : props.theme.buttonGreenColor,
      backgroundColor: props.filled
        ? props.theme.buttonGreenBackgroundHover
        : props.theme.buttonGreenBackground,
      borderColor: props.theme.buttonGreenBorderColor,

      "&:hover, &:focus, &:active": {
        color: props.theme.buttonGreenColorHover,
        backgroundColor: props.theme.buttonGreenBackgroundHover,
        borderColor: props.theme.buttonGreenBorderColorHover
      }
    },
  props =>
    props.stripped && {
      border: 0,
      padding: `calc(${toPx(props.theme.spaceThirds)} - 3px) 0`,

      [media("desktop")]: {
        padding: `calc(${toPx(props.theme.spaceThirds)} - 3px) 0`
      },

      "&:hover, &:focus, &:active": {
        border: 0,
        background: "transparent"
      }
    },
  props =>
    props.stripped &&
    props.theme.name === "default" && {
      "&:hover, &:focus, &:active": {
        color: props.theme.buttonAlternativeColorHover
      }
    },
  props =>
    props.stripped &&
    props.theme.name === "alternative" && {
      "&:hover, &:focus, &:active": {
        color: props.theme.buttonAlternativeColor
      }
    },
  props =>
    props.themeName !== "transparent" &&
    props.theme.name === "transparent" && {
      "&:hover, &:focus, &:active": {
        background: props.theme.buttonDefaultBackgroundHover,
        color: props.theme.buttonDefaultColorHover,
        borderColor: props.theme.buttonDefaultBorderColorHover
      }
    },
  props =>
    props.themeName !== "green" &&
    props.theme.name === "green" && {
      "&:hover, &:focus, &:active": {
        background: props.theme.buttonDefaultBackgroundHover,
        color: props.theme.buttonDefaultColorHover,
        borderColor: props.theme.buttonDefaultBorderColorHover
      }
    },
  props =>
    props.stripped &&
    props.theme.name === "transparent" && {
      "&:hover, &:focus, &:active": {
        background: "transparent",
        color: props.theme.buttonAlternativeColorHover
      }
    },
  props =>
    props.stripped &&
    props.theme.name === "green" && {
      "&:hover, &:focus, &:active": {
        background: "transparent",
        color: props.theme.buttonAlternativeColorHover
      }
    },
  props =>
    props.subline && {
      flexWrap: "wrap",
      textAlign: "left"
    },
  props =>
    props.stretch && {
      display: "block",
      width: "100%",

      [media("tablet")]: {
        marginRight: 0
      }
    },
  props =>
    props.icon && {
      display: "inline-flex",
      alignItems: "center"
    },
  props =>
    props.stretch &&
    props.icon && {
      display: "flex",
      justifyContent: "space-between"
    },
  props =>
    props.uppercase && {
      textTransform: "uppercase",
      fontSize: "14px",
      letterSpacing: "0.08em"
    },
  props =>
    props.disabled && {
      opacity: 0.3,
      pointerEvents: "none"
    },
  props =>
    props.buttonType === "heavy" && {
      fontFamily: props.theme.fontSecondary,
      fontWeight: 900
    },
  props =>
    props.buttonType === "bold" && {
      fontFamily: props.theme.fontSecondary,
      fontWeight: 700
    },
  props =>
    props.buttonSize === "tiny" && {
      fontSize: "8px",
      padding: "calc(8px - 3px) 8px",
      [media("tabletFluid")]: {
        padding: "calc(8px - 3px) 8px",
        fontSize: "8px"
      }
    },
  props =>
    props.buttonSize === "small" && {
      fontSize: "11px",
      padding: "calc(15px - 3px) 18px",
      [media("tabletFluid")]: {
        padding: "calc(15px - 3px) 18px",
        fontSize: "11px"
      }
    },
  props =>
    props.buttonSize === "large" && {
      fontSize: "18px",
      [media("tabletFluid")]: {
        fontSize: "24px"
      }
    },
  props =>
    props.buttonSize === "mega" && {
      fontSize: "18px",
      [media("tablet")]: {
        fontSize: "24px"
      },
      [media("tabletFluid")]: {
        fontSize: "34px"
      }
    }
);
