import { useState } from 'react';

export default function useInput(initialValue) {
  const [value, setValue] = useState(initialValue || '');
  const [focused, setFocus] = useState(false);
  const [keyCode, setKeyCode] = useState(-1);
  const [keyPressCount, setKeyPressCount] = useState(0);
  function setValueFromEvent(e) {
    setValue(e.target.value);
  }
  function handleFocus(e) {
    setFocus(true);
  }
  function handleBlur(e) {
    setFocus(false);
  }
  function handleKeyDown(e) {
    const keyCode = e.keyCode;
    setKeyCode(keyCode);
    setKeyPressCount(keyPressCount+ 1);
  }

  return [
    value,
    {
      onChange: setValueFromEvent,
      onKeyDown: handleKeyDown,
      onFocus: handleFocus,
      onBlur: handleBlur,
    },
    focused,
    keyCode,
    keyPressCount,
  ];
}
