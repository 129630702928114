/**
 * Transforms hex to rgb(a)
 * NOTE: just works for full hex values not short syntax!
 *
 * Usage:
 * hexToRGB('#FF0000')
 * hexToRGB('#FF0000', 0.5);
 *
 * @param {string} hex
 * @param {string} alpha
 *
 * @return string
 */
export function hexToRGB(hex, alpha) {
  if (typeof hex !== "string") return "red";
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return alpha ? `rgba(${r},${g},${b},${alpha})` : `rgb(${r},${g},${b})`;
}

/**
 * Create linear gradient with a given
 * direction and n color stops without percentage.
 *
 * Usage:
 * gradientLinear('135deg', ['red', 'orange'] )
 * gradientLinear('135deg', vars.gradient.green )
 *
 * @param {string} direction
 * @param {array} colors
 * @param {array} alphaMap
 * @param {array} positionMap
 *
 * @return string
 */
export const gradientLinear = (direction, colors, alphaMap, positionMap) => {
  return alphaMap
    ? positionMap
      ? `linear-gradient(${direction}, ${colors
          .map(
            (color, i) => `${hexToRGB(color, alphaMap[i])} ${positionMap[i]}`
          )
          .join(",")})`
      : `linear-gradient(${direction}, ${colors
          .map((color, i) => hexToRGB(color, alphaMap[i]))
          .join(",")})`
    : `linear-gradient(${direction}, ${colors.map(color => color).join(",")})`;
};

export const gradientRadial = (colors, alphaMap, positionMap) => {
  return alphaMap
    ? positionMap
      ? `radial-gradient(${colors
          .map(
            (color, i) => `${hexToRGB(color, alphaMap[i])} ${positionMap[i]}`
          )
          .join(",")})`
      : `radial-gradient(${colors
          .map((color, i) => hexToRGB(color, alphaMap[i]))
          .join(",")})`
    : `radial-gradient(${colors.map(color => color).join(",")})`;
};

/**
 * Checks if we are in IE11.
 * To be used sparingly and in context of style blocks only!
 */
export const isBrowserIE11 = () =>
  typeof document !== "undefined" &&
  "-ms-scroll-limit" in document.documentElement.style &&
  "-ms-ime-align" in document.documentElement.style;
