import styled from "@emotion/styled";
import media from "utils/mediaqueries";
import Button from "components/Button";

export const TeaserMultiStyled = styled("div")({
  display: "inline-block",
  width: "100%",

  [media("laptop")]: {
    width: "50%"
  }
});

export const ButtonStyled = styled(Button)({
  display: "flex"
});
