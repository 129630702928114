const vars = require('components/Layout/variables');
const utils = require('utils/styles');

module.exports = {
  name: 'transparent',
  inherit: null,

  // colorPrimary: INHERIT,
  // colorPrimaryHover: INHERIT
  // colorSecondary: INHERIT
  // colorSecondaryHover: INHERIT
  // colorTertiary: INHERIT
  // colorTertiaryHover: INHERIT,

  backgroundPrimary: 'transparent',
  backgroundPrimaryHover: 'transparent',
  // backgroundSecondary: INHERIT,
  // backgroundSecondaryHover: INHERIT,
  // backgroundTertiary: INHERIT,
  // backgroundTertiaryHover: INHERIT,

  borderColorPrimary: 'transparent',
  borderColorPrimaryHover: 'transparent',
  // borderColorSecondary: INHERIT,
  // borderColorSecondaryHover: INHERIT,
  // borderColorTertiary: INHERIT,
  // borderColorTertiaryHover: INHERIT,

  // fontPrimary: INHERIT,
  // fontSecondary: INHERIT,

  // ...vars.sizes, INHERIT

  // component variables
  // buttonDefaultColor: 'hotpink',
  // buttonDefaultColorHover: INHERIT,
  // buttonDefaultBackground: INHERIT,
  // buttonDefaultBackgroundHover: INHERIT,
  // buttonDefaultBorderColor: INHERIT,
  // buttonDefaultBorderColorHover: INHERIT,

  // buttonAlternativeColor: 'green',
  // buttonAlternativeColorHover: INHERIT,
  // buttonAlternativeBackground: INHERIT,
  // buttonAlternativeBackgroundHover: INHERIT,
  // buttonAlternativeBorderColor: INHERIT,
  // buttonAlternativeBorderColorHover: INHERIT,

  // buttonGreenColor: 'orange',
  // buttonGreenColorHover: INHERIT,
  // buttonGreenBackground: INHERIT,
  // buttonGreenBackgroundHover: INHERIT,
  // buttonGreenBorderColor: INHERIT,
  // buttonGreenBorderColorHover: INHERIT,

  overlayBackground: 'transparent',
  sectionTextBackground: utils.gradientLinear(
    'to right',
    vars.gradients.greyDarkToGrey,
  ),
};
