import React from 'react';
import { graphql } from 'gatsby';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { parseAsText } from 'utils/parser';
import { TeaserMultiStyled, ButtonStyled } from './styles';

const TeaserMulti = props => {
  const { title, text, link } = props;

  return (
    <TeaserMultiStyled>
      <ButtonStyled
        to={link[0].fullUri}
        icon="arrowRight"
        type="bold"
        size="large"
        subline={text}
        uppercase
        stripped
        forceButtonTextShrink
      >
        {parseAsText(title)}
      </ButtonStyled>
    </TeaserMultiStyled>
  );
};

TeaserMulti.propTypes = {};

TeaserMulti.defaultProps = {};

export default TeaserMulti;

export const query = graphql`
  fragment NavMainMultiTeaserQuery on Craft_NavMainMultiTeaserNavMainMultiTeaser {
    id
    title: navMainMultiTeaserTitle
    text: navMainMultiTeaserText
    link: navMainMultiTeaserLink {
      id
      fullUri
    }
  }
`;
