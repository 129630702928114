import React from 'react';

const LocationContent = ({ location }) => {
  if (!location?.length) return null;

  const { locationAddress, locationZip, locationCity } = location[0];

  return (
    <span>
      {locationAddress}
      <br />
      {locationZip} {locationCity}
    </span>
  );
};

export default LocationContent;
