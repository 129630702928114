import React from "react";
import SiteSwitch from "components/SiteSwitch";
import { graphql } from "gatsby";
import { NavHeaderStyled, NavHeaderItemStyled } from "./styles";

const NavigationHeader = ({ items, currentSiteId, alternateEntries }) => {
  return (
    <NavHeaderStyled>
      {items &&
        items.map((item) => {
          const linkTarget =
            item.navHeaderLink?.entry?.fullUri || item.navHeaderLink?.url;
          return linkTarget ? (
            <NavHeaderItemStyled key={item.id} to={linkTarget}>
              {item.navHeaderLink?.text || item.title}
            </NavHeaderItemStyled>
          ) : null;
        })}

      <SiteSwitch
        currentSiteId={currentSiteId}
        alternateEntries={alternateEntries}
      />
    </NavHeaderStyled>
  );
};

export default NavigationHeader;

export const query = graphql`
  fragment NavigationHeaderQuery on Craft_NavigationHeader {
    id
    title
    navHeaderLink {
      url
      text
      entry {
        title
        fullUri
      }
    }
  }
`;
