import React from 'react';

import { styled } from 'utils/emotion';

import Blockquote from 'components/Blockquote';

const RedactorBlockquoteStyled = styled(Blockquote)({});

export default function RedactorBlockquote({ children, element }) {
  return (
    <RedactorBlockquoteStyled element={element}>
      {children}
    </RedactorBlockquoteStyled>
  );
}
