import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaLanguage = ({ language, alternateEntries }) => {
  if (alternateEntries.length === 0) {
    return null;
  }

  return (
    <Helmet>
      <meta name="language" content={language} />
      <meta property="og:locale" content={language} />

      {/* Display canonical link for every alternative site */}
      {/* robine: TODO: disabled this until somebody knows how to get the absolute url for the entry. */}
      {/* {alternateEntries.map(entry => (
        <link
          key={`alternate-${entry.id}-${entry.site.id}`}
          rel="alternate"
          hrefLang={entry.site.handle}
          href={entry.fullUri || '/'}
        />
      ))} */}
      {/* <meta property="og:locale:alternate" content="TODO" /> */}
    </Helmet>
  );
};

MetaLanguage.propTypes = {
  language: PropTypes.string.isRequired,
  alternateEntries: PropTypes.array,
};

MetaLanguage.defaultProps = {
  alternateEntries: [],
};

export default MetaLanguage;
