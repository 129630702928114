import { parseAsText } from 'utils/parser';

// Capitalizes the first letter of a string
export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

// Transmutes to pixel if value is number otherwise it keeps the string value as its expected to be percent
export const toPx = value => {
  if (typeof value === 'number') return `${value}px`;
  return value;
};

// https://stackoverflow.com/questions/909003/getting-the-first-index-of-an-object
// this is kind of hacky but it makes it possible to parse redactor combinations of lets
// say images and text and still get out a huge pile of text for non-specific/global
// use cases e.g. PageBuilder or Magazine"Builder" it doesnt matter. ~DH
export const getRedactorContent = item =>
  item?.[Object.keys(item)?.[1]] &&
  `${parseAsText(item?.[Object.keys(item)?.[1]]?.content)} `;
