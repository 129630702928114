import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Headline from 'components/Headline';
import Text from 'components/Text';
import Button from 'components/Button';
import { parseAsText } from 'utils/parser';
import { TeaserSingleStyled } from './styles';

const TeaserSingle = props => {
  const { title, text, navMainSingleTeaserLink } = props;
  const link = navMainSingleTeaserLink;

  return (
    <TeaserSingleStyled>
      <Headline margin="0 0 6px 0" bold>{parseAsText(title)}</Headline>
      <Text margin="50px 0">{text}</Text>
      {link && (
        <Button to={link.entry.fullUri || link.url} icon="arrowRight" stripped>
          {link.text}
        </Button>
      )}
    </TeaserSingleStyled>
  );
};

TeaserSingle.propTypes = {};

TeaserSingle.defaultProps = {};

export default TeaserSingle;

export const query = graphql`
  fragment NavMainSingleTeaserQuery on Craft_NavMainSingleTeaserNavMainSingleTeaser {
    id
    title: navMainSingleTeaserTitle
    text: navMainSingleTeaserText
    navMainSingleTeaserLink {
      text
      url
      entry {
        uri
        fullUri
      }
    }
  }
`;
