module.exports = {
  siteMetadata: {
    title: "EVO Fitness",
    siteTitle: "EVO Fitness",
    siteUrl: "https://evofitness.de", // No trailing slash (used to create xml sitemap)
  },
  // webfontconfig: {
  //   google: {
  //     families: ['Open Sans:400,700', 'Montserrat:400,600,700,900']
  //   },
  // },
  webfontconfig: {
    google: {
      families: ["Open Sans:400,700", "Montserrat:400,600,700,900"],
    },
    fonts: {
      google: [
        {
          family: "Open Sans",
          variants: ["400", "700"],
          fontDisplay: "swap",
          //subsets: ['latin']
          // text: 'Hello'
          //strategy: 'selfHosted' // 'base64' || 'cdn'
        },
        {
          family: "Montserrat",
          variants: ["400", "600", "700", "900"],
          fontDisplay: "swap",
          //subsets: ['latin']
          // text: 'Hello'
          //strategy: 'selfHosted' // 'base64' || 'cdn'
        },
      ],
    },
    //formats: ['woff2', 'woff'],
    //useMinify: true,
    //usePreload: true,
    //usePreconnect: false,
  },
  responsive: {
    spacing: {
      default: 24,
    },
    breakpoints: {
      mobile: 0,
      tablet: 481,
      tabletFluid: 737,
      laptop: 977,
      desktop: 1440,
    },
    wrapSizes: {
      mobile: "100%",
      tablet: "100%",
      tabletFluid: 720,
      laptop: 960,
      desktop: 1240,
    },
  },
};
