import { useState } from 'react';

export default function useMouseOver() {
  const [event, setHover] = useState({
    hovered: false,
    target: null,
  });
  return [
    event,
    {
      onMouseEnter: e => {
        const target = e.target;
        setHover({
          hovered: true,
          target,
        });
      },
      onMouseLeave: e => {
        const target = e.target;
        setHover({
          hovered: false,
          target,
        });
      },
    },
  ];
}
