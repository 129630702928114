// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-error-404-error-404-js": () => import("./../../../src/templates/error404/error404.js" /* webpackChunkName: "component---src-templates-error-404-error-404-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-magazine-all-magazine-all-js": () => import("./../../../src/templates/magazineAll/magazineAll.js" /* webpackChunkName: "component---src-templates-magazine-all-magazine-all-js" */),
  "component---src-templates-magazine-article-magazine-entry-js": () => import("./../../../src/templates/magazineArticle/magazineEntry.js" /* webpackChunkName: "component---src-templates-magazine-article-magazine-entry-js" */),
  "component---src-templates-magazine-category-js": () => import("./../../../src/templates/magazine/category.js" /* webpackChunkName: "component---src-templates-magazine-category-js" */),
  "component---src-templates-magazine-magazine-js": () => import("./../../../src/templates/magazine/magazine.js" /* webpackChunkName: "component---src-templates-magazine-magazine-js" */),
  "component---src-templates-pages-pages-clubs-js": () => import("./../../../src/templates/pages/pagesClubs.js" /* webpackChunkName: "component---src-templates-pages-pages-clubs-js" */),
  "component---src-templates-pages-pages-js": () => import("./../../../src/templates/pages/pages.js" /* webpackChunkName: "component---src-templates-pages-pages-js" */),
  "component---src-templates-payment-feedback-payment-feedback-js": () => import("./../../../src/templates/payment-feedback/payment-feedback.js" /* webpackChunkName: "component---src-templates-payment-feedback-payment-feedback-js" */)
}

