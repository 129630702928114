import React from "react";
import PropTypes from "prop-types";

import media from "utils/mediaqueries";
import { styled } from "utils/emotion";
import { toPx } from "utils/strings";
import "./font.css";

function getFontStyle(props) {
  let styles = {};

  switch (props.titleFont) {
    case "fat":
      styles = {
        fontWeight: "900",
      };
      break;

    case "bold":
      styles = {
        fontWeight: "700",
      };
      break;

    case "normal":
      styles = {
        fontWeight: "500",
      };
      break;

    case "thin":
      styles = {
        fontWeight: "300",
      };
      break;

    case "handwriting":
      styles = {
        fontWeight: "500",
        fontFamily: "Northwell",
      };
      break;

    case "outline":
      styles = {
        fontWeight: "900",
        color: "transparent",
        textTransform: "uppercase",
        WebkitTextStrokeWidth: "2px",
        lineHeight: 1.2,
        WebkitTextStrokeColor: props.color || props.theme.colorPrimary,
      };
      break;

    case "invert":
      styles = {
        fontWeight: "900",
        color: props.theme.colorSecondary,
        background: props.color || props.theme.colorPrimary,
        lineHeight: 1.2,
        display: "inline",
        boxShadow: `-${10}px 0 0 ${
          props.color || props.theme.colorPrimary
        }, ${10}px 0 0 ${props.color || props.theme.colorPrimary}`,
      };
      break;

    default:
      styles = {};
  }
  return styles;
}

const StyledH1 = styled("h1")(
  {
    lineHeight: 1,
    fontWeight: 900,
  },
  (props) => ({
    position: props.position,
    fontSize: props.headlinefontsize || 36,
    color: props.color || props.theme.colorPrimary,
    margin: props.margin,
    letterSpacing: props.letterSpacing || toPx(3),
    lineHeight: props.lineHeight || 1.3,
    fontWeight:
      (props.heavy && "900") || (props.bold && "700") || props.fontWeight,
    textTransform: props.uppercase && "uppercase",

    [media(0, "tablet")]: {
      fontSize: props.headlinefontsizeMobile || 28,
    },

    [media("tabletFluid")]: {
      fontSize: props.headlinefontsize || 50,
      lineHeight: props.lineHeight || 1.3,
      maxWidth: props.maxWidth || "100%",
    },

    ...getFontStyle(props),
  })
);

const StyledH2 = styled("h2")(
  {
    lineHeight: 1.2,
    textTransform: "uppercase",
    fontWeight: 900,

    [media(0, "tablet")]: {
      fontSize: 26,
      lineHeight: 1.4,
    },
  },
  (props) => ({
    position: props.position,
    fontSize: props.headlinefontsize || 34,
    color: props.color || props.theme.colorPrimary,
    margin: props.margin,
    letterSpacing: props.letterSpacing || toPx(1),
    fontWeight:
      (props.heavy && "900") || (props.bold && "700") || props.fontWeight,
    textTransform: props.uppercase && "uppercase",

    [media("tabletFluid")]: {
      maxWidth: props.maxWidth || "100%",
    },

    ...getFontStyle(props),
  })
);

const StyledH3 = styled("h3")(
  {
    lineHeight: "32px",
    textTransform: "uppercase",
    fontWeight: 900,

    [media(0, "tablet")]: {
      fontSize: 23,
      lineHeight: "1.25",
    },
  },
  (props) => ({
    position: props.position,
    letterSpacing: props.letterSpacing || toPx(3),
    fontSize: props.headlinefontsize || 24,
    color: props.color || props.theme.colorPrimary,
    margin: props.margin,
    fontWeight:
      (props.heavy && "900") || (props.bold && "700") || props.fontWeight,
    textTransform: props.uppercase && "uppercase",

    [media("tabletFluid")]: {
      maxWidth: props.maxWidth || "100%",
    },

    ...getFontStyle(props),
  })
);

const StyledH4 = styled("h4")((props) => ({
  position: props.position,
  fontSize: props.headlinefontsize,
  letterSpacing: props.letterSpacing || toPx(3),
  color: props.color || props.theme.colorPrimary,
  margin: props.margin,
  fontWeight:
    (props.heavy && "900") || (props.bold && "700") || props.fontWeight,
  textTransform: props.uppercase && "uppercase",

  [media("tabletFluid")]: {
    maxWidth: props.maxWidth || "100%",
  },

  ...getFontStyle(props),
}));

const StyledH5 = styled("h5")(
  {
    fontWeight: "400",
    wordBreak: "break-all",
  },
  (props) => ({
    position: props.position,
    fontSize: props.headlinefontsize || 13,
    fontFamily: props.theme.fontPrimary,
    color: props.color || props.theme.colorPrimary,
    margin: props.margin,
    letterSpacing: props.letterSpacing || toPx(0.8),
    fontWeight:
      (props.heavy && "900") || (props.bold && "700") || props.fontWeight,
    textTransform: props.uppercase && "uppercase",

    [media("tabletFluid")]: {
      maxWidth: props.maxWidth || "100%",
    },

    ...getFontStyle(props),
  })
);

const StyledH6 = styled("h6")(
  {
    wordBreak: "break-all",
  },
  (props) => ({
    position: props.position,
    color: props.color || props.theme.colorPrimary,
    margin: props.margin,
    letterSpacing: props.letterSpacing,
    fontWeight:
      (props.heavy && "900") || (props.bold && "700") || props.fontWeight,
    textTransform: props.uppercase && "uppercase",

    [media("tabletFluid")]: {
      maxWidth: props.maxWidth || "100%",
    },

    ...getFontStyle(props),
  })
);

export default function Headline({
  element,
  children,
  margin,
  headlinefontsize,
  headlinefontsizeMobile,
  className,
  uppercase,
  heavy,
  bold,
  position,
  maxWidth,
  letterSpacing,
  lineHeight,
  titleFont,
}) {
  const ComponentList = {
    h1: StyledH1,
    h2: StyledH2,
    h3: StyledH3,
    h4: StyledH4,
    h5: StyledH5,
    h6: StyledH6,
  };
  const Component = ComponentList[element];

  return (
    <Component
      margin={margin}
      headlinefontsize={headlinefontsize}
      headlinefontsizeMobile={headlinefontsizeMobile}
      className={className}
      lineHeight={lineHeight}
      uppercase={uppercase}
      heavy={heavy}
      bold={bold}
      position={position}
      letterSpacing={letterSpacing}
      maxWidth={maxWidth}
      titleFont={titleFont}
      elm={element}
    >
      {children}
    </Component>
  );
}

Headline.propTypes = {
  element: PropTypes.string,
  uppercase: PropTypes.bool,
  heavy: PropTypes.bool,
  bold: PropTypes.bool,
  letterSpacing: PropTypes.number,
  lineHeight: PropTypes.number,
  headlinefontsize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  position: PropTypes.string,
  maxWidth: PropTypes.string,
};

Headline.defaultProps = {
  element: "h3",
  uppercase: false,
  heavy: false,
  bold: false,
  letterSpacing: null,
  lineHeight: null,
  headlinefontsize: null,
  position: null,
  maxWidth: null,
};
