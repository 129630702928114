import React, { useContext } from "react";
import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

import Grid from "components/Grid";
import GridItem from "components/GridItem";
import Logo from "components/Logo";
import NavigationHeader from "components/NavigationHeader";
import NavigationMain from "components/NavigationMain";
import Button from "components/Button";
import { LayoutContext } from "components/Layout/Layout";

import themeDefault from "components/Layout/themes/default";
import { useScrollPosition } from "utils/hooks";

const StyledHeaderWrap = styled("div")(
  {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "6",
    width: "100%",
    padding: "12px 12px 0 12px",
    [media("tablet")]: {
      padding: "24px 24px 0 24px",
    },
  },
  (props) => ({
    height: props.theme.sizeHeaderHeight,
  })
);

const StyledHeader = styled("div")(
  {
    width: "100%",
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  (props) => ({
    "&:before": {
      boxSizing: "border-box",
      content: '""',
      position: "absolute",
      width: "100%",
      padding: "0 24px",
      background: props.isTop ? "transparent" : "rgba(0,0,0,0.8)",
      height: props.theme.sizeHeaderHeight - 24,
    },
  })
);
const GridStyled = styled(Grid)({
  height: "auto",
  flexBasis: "initial",
  paddingRight: 20,
});

const HamburgerGridItemStyled = styled(GridItem)({}, (props) => ({
  display:
    (props.pageHideMenu && props.isTop) || props.menuOpen
      ? "none"
      : "inline-block",
}));

const ButtonsGridItemStyled = styled(GridItem)({}, (props) => ({
  display: "none",

  [media("tabletFluid")]: {
    display:
      props.menuOpen || (props.pageHideCtas && !props.isTop) || !props.isHome
        ? "inline-block"
        : "none",
  },
}));

export default function Header({
  navigationItems,
  currentSiteId,
  alternateEntries,
  currentBaseUrl,
  pageHideMenu,
  pageHideCtas,
  isHome,
  signUp,
  siteId,
}) {
  const { menuOpen, setMenuOpen } = useContext(LayoutContext);
  const scrollpos = useScrollPosition();
  const isTop = !scrollpos || scrollpos < 80;

  return (
    <StyledHeaderWrap>
      <StyledHeader isTop={isTop}>
        <GridStyled top between isNestedForce gutter={0}>
          <GridItem mobile={0}>
            <Grid
              middle
              gutter={0}
              height={`${themeDefault.sizeHeaderHeight - 24}`}
            >
              <HamburgerGridItemStyled
                mobile={0}
                isTop={isTop}
                // pageHideMenu={pageHideMenu}
                // menuOpen={menuOpen}
              >
                <NavigationMain siteId={siteId} />
              </HamburgerGridItemStyled>
              <GridItem mobile={0}>
                <Logo to={currentBaseUrl} />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem mobile={0}>
            <Grid
              middle
              gutter={0}
              height={`${themeDefault.sizeHeaderHeight - 24}`}
            >
              <GridItem mobile={0}>
                <NavigationHeader
                  currentSiteId={currentSiteId}
                  alternateEntries={alternateEntries}
                  items={navigationItems}
                />
              </GridItem>
              <ButtonsGridItemStyled
                mobile={0}
                isTop={isTop}
                menuOpen={menuOpen}
                isHome={isHome}
                pageHideCtas={pageHideCtas}
              >
                {signUp?.ctaTrialLink && (
                  <Button
                    size="small"
                    margin="0"
                    filled
                    themeName="default"
                    to={`${signUp.ctaTrialLink.entry?.fullUri ||
                      signUp.ctaTrialLink.url}`}
                  >
                    {signUp.ctaTrialLink.text}
                  </Button>
                )}
                {signUp?.ctaSignUpLink && (
                  <Button
                    size="small"
                    margin="0"
                    filled
                    themeName="green"
                    to={`${signUp.ctaSignUpLink.entry?.fullUri ||
                      signUp.ctaSignUpLink.url}`}
                  >
                    {signUp.ctaSignUpLink.text}
                  </Button>
                )}
              </ButtonsGridItemStyled>
            </Grid>
          </GridItem>
        </GridStyled>
      </StyledHeader>
    </StyledHeaderWrap>
  );
}
