import { useState, useEffect } from 'react';

// Hook
export default function useScrollPosition() {
  const isClient = typeof document === 'object';

  function getPosition() {
    return isClient
      ? document.documentElement.scrollTop || document.body.scrollTop
      : undefined;
  }

  const [scrollPosition, setScrollPosition] = useState(getPosition);

  function handleScrollPosition() {
    setScrollPosition(getPosition());
  }

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    window.addEventListener('scroll', handleScrollPosition);
    return () => window.removeEventListener('scroll', handleScrollPosition);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return scrollPosition || getPosition();
}

// Usage
// function App() {
//   const scrollPosition = useScrollPosition();

//   return (
//     <div>
//       {scrollPosition.scrollPosition}px
//     </div>
//   );
// }
