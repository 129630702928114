import React from 'react';
import PropTypes from 'prop-types';

import { styled } from 'utils/emotion';

import Headline from 'components/Headline';

const RedactorHeadlineStyled = styled(Headline)({}, props => ({}));

export default function RedactorHeadline({ element, children, id }) {
  return (
    <RedactorHeadlineStyled id={id} element={element}>
      {children}
    </RedactorHeadlineStyled>
  );
}

Headline.propTypes = {
  element: PropTypes.string,
  id: PropTypes.string,
};

Headline.defaultProps = {
  element: 'h3',
  id: '',
};
