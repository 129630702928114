// const _ = require('lodash')

const healOptions = (args, defaultArgs) => {
  // const options = _.defaults({}, args, defaultArgs)
  const options = { ...defaultArgs, ...args }
  // only set width to 400 if neither width nor height is passed
  if (options.width === undefined && options.height === undefined) {
    options.width = 400
  } else if (options.width !== undefined) {
    options.width = parseInt(options.width, 10)
  } else if (options.height !== undefined) {
    options.height = parseInt(options.height, 10)
  }

  // only set maxWidth to 800 if neither maxWidth nor maxHeight is passed
  if (options.maxWidth === undefined && options.maxHeight === undefined) {
    options.maxWidth = 800
  } else if (options.maxWidth !== undefined) {
    options.maxWidth = parseInt(options.maxWidth, 10)
  } else if (options.maxHeight !== undefined) {
    options.maxHeight = parseInt(options.maxHeight, 10)
  }

  return options
}

function fluid(image, args = {}, overrideArgs = {}) {
  const options = healOptions(args)

  const { width, height } = image
  const pixelRatio = 1

  if (image?.url) {
    const type = image.url.split('.').pop()
    if (type === 'svg') {
      return {
        aspectRatio: image.width / image.height,
        src: image.url,
        srcSet: null,
        sizes: null,
      }
    }
  }

  // if no maxWidth is passed, we need to resize the image based on the passed maxHeight
  const fixedDimension =
    options.maxWidth === undefined ? 'maxHeight' : 'maxWidth'

  if (options[fixedDimension] < 1) {
    throw new Error(
      `${fixedDimension} has to be a positive int larger than zero (> 0), now it's ${options[fixedDimension]}`
    )
  }

  let presentationWidth
  let presentationHeight
  if (fixedDimension === 'maxWidth') {
    presentationWidth = Math.min(
      options.maxWidth,
      Math.round(width / pixelRatio)
    )
    presentationHeight = Math.round(presentationWidth * (height / width))
  } else {
    presentationHeight = Math.min(
      options.maxHeight,
      Math.round(height / pixelRatio)
    )
    presentationWidth = Math.round(presentationHeight * (width / height))
  }

  // If the users didn't set default sizes, we'll make one.
  if (!options.sizes) {
    options.sizes = `(max-width: ${presentationWidth}px) 100vw, ${presentationWidth}px`
  }

  // Create sizes (in width) for the image if no custom breakpoints are
  // provided. If the max width of the container for the rendered markdown file
  // is 800px, the sizes would then be: 200, 400, 800, 1200, 1600, 2400.
  //
  // This is enough sizes to provide close to the optimal image size for every
  // device size / screen resolution while (hopefully) not requiring too much
  // image processing time (Sharp has optimizations thankfully for creating
  // multiple sizes of the same input file)
  const fluidSizes = [
    { size: 10, src: image.url_10 },
    { size: 100, src: image.url_100 },
    { size: 160, src: image.url_160 },
    { size: 320, src: image.url_320 },
    { size: 480, src: image.url_480 },
    { size: 768, src: image.url_768 },
    { size: 1200, src: image.url_1200 },
    { size: 1920, src: image.url_1920 },
  ]

  // Sort sizes for prettiness.
  // const sortedSizes = _.sortBy(fluidSizes, 'size')
  const sortedSizes = fluidSizes.sort((a, b) => a.size - b.size)

  // Queue sizes for processing.
  const dimensionAttr = fixedDimension === 'maxWidth' ? 'width' : 'height'
  const otherDimensionAttr = fixedDimension === 'maxWidth' ? 'height' : 'width'

  const images = sortedSizes.map(({ size, src }) => {
    const arrrgs = {
      ...options,
      src,
      aspectRatio: overrideArgs.aspectRatio || width / height,
      [otherDimensionAttr]: undefined,
      [dimensionAttr]: Math.round(size),
      distanceToFallbackSize: Math.abs(
        options[fixedDimension] - Math.round(size)
      ),
    }
    // Queue sizes for processing.
    if (options.maxWidth !== undefined && options.maxHeight !== undefined) {
      arrrgs.height = Math.round(size * (options.maxHeight / options.maxWidth))
    }

    return arrrgs
  })

  // Construct src and srcSet strings.
  // const originalImg = _.maxBy(images, image => image.width).src;
  // const fallbackSrc = _.minBy(images, (image) =>
  //   Math.abs(options[fixedDimension] - image[dimensionAttr])
  // ).src

  const fallbackSrc = images.sort(
    (a, b) => a.distanceToFallbackSize - b.distanceToFallbackSize
  )[0].src

  const srcSet = images
    .map((image) => {
      if (image.maxWidth >= image.width)
        return `${image.src} ${Math.round(image.width)}w`
      return
    })
    .join(',\n')

  return {
    aspectRatio: images[0].aspectRatio,
    src: fallbackSrc,
    srcSet,
    sizes: options.sizes,
  }
}

// @todo: implement fixed option for images.
// async function fixed({ file, args = {}, reporter, cache }) {
//   const options = healOptions(args, {});

//   // if no width is passed, we need to resize the image based on the passed height
//   const fixedDimension = options.width === undefined ? `height` : `width`;

//   // Create sizes for different resolutions — we do 1x, 1.5x, 2x, and 3x.
//   const sizes = [];
//   sizes.push(options[fixedDimension]);
//   sizes.push(options[fixedDimension] * 1.5);
//   sizes.push(options[fixedDimension] * 2);
//   sizes.push(options[fixedDimension] * 3);
//   const dimensions = getImageSize(file);

//   const filteredSizes = sizes.filter(
//     size => size <= dimensions[fixedDimension],
//   );

//   // If there's no fluid images after filtering (e.g. image is smaller than what's
//   // requested, add back the original so there's at least something)
//   if (filteredSizes.length === 0) {
//     filteredSizes.push(dimensions[fixedDimension]);
//     console.warn(
//       `
//                  The requested ${fixedDimension} "${
//         options[fixedDimension]
//       }px" for a resolutions field for
//                  the file ${file.absolutePath}
//                  was larger than the actual image ${fixedDimension} of ${
//         dimensions[fixedDimension]
//       }px!
//                  If possible, replace the current image with a larger one.
//                  `,
//     );
//   }

//   // Sort images for prettiness.
//   const sortedSizes = _.sortBy(filteredSizes);

//   const images = sortedSizes.map(size => {
//     const arrrgs = {
//       ...options,
//       [fixedDimension]: Math.round(size),
//     };
//     // Queue images for processing.
//     if (options.width !== undefined && options.height !== undefined) {
//       arrrgs.height = Math.round(size * (options.height / options.width));
//     }

//     return queueImageResizing({
//       file,
//       args: arrrgs,
//       reporter,
//     });
//   });

//   const base64Args = {
//     duotone: options.duotone,
//     grayscale: options.grayscale,
//     rotate: options.rotate,
//     toFormat: options.toFormat,
//   };

//   // Get base64 version
//   const base64Image = await base64({ file, args: base64Args, reporter, cache });

//   const fallbackSrc = images[0].src;
//   const srcSet = images
//     .map((image, i) => {
//       let resolution;
//       switch (i) {
//         case 0:
//           resolution = `1x`;
//           break;
//         case 1:
//           resolution = `1.5x`;
//           break;
//         case 2:
//           resolution = `2x`;
//           break;
//         case 3:
//           resolution = `3x`;
//           break;
//         default:
//       }
//       return `${image.src} ${resolution}`;
//     })
//     .join(`,\n`);

//   const originalName = file.base;

//   return {
//     base64: base64Image.src,
//     aspectRatio: images[0].aspectRatio,
//     width: images[0].width,
//     height: images[0].height,
//     src: fallbackSrc,
//     srcSet,
//     originalName: originalName,
//   };
// }

module.exports = {
  fluid,
}
