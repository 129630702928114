import React from "react";
import PropTypes from "prop-types";
import { styled } from "utils/emotion";

const StyledOverlay = styled("div")(
  {
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: "0"
  },
  props => ({
    position: props.position ? props.position : "absolute",
    background: props.theme[props.background] || props.background
  })
);

export default function Overlay({ position, background }) {
  const validThemeKeys = {
    overlayBackground: "overlayBackground",
    overlayMagazineBackground: "magazineBackground",
    sectionImageBackground: "sectionImageBackground",
    sectionImageTextBackground: "sectionImageTextBackground",
    overlayGradientBackground: "overlayGradientBackground",
    overlayRadialGradientBackground: "overlayRadialGradientBackground"
  };

  return (
    <StyledOverlay
      position={position}
      background={validThemeKeys[background]}
    />
  );
}

Overlay.propTypes = {
  background: PropTypes.string
};

Overlay.defaultProps = {
  background: "overlayBackground"
};
