import React from "react";
import { styled } from "utils/emotion";
import PropTypes from "prop-types";
import media from "utils/mediaqueries";

const StyledWrap = styled("div")((props) => ({
  display: props.display,
  position: props.position,
  margin: props.margin,
  padding: props.padding,
  height: props.height,
  background: props.background,
  alignItems: props.alignItems,
  overflow: props.overflow || "hidden",
  maxWidth: "100vw",
  overflowX: "hidden",
  zIndex: 1,

  [media("tablet")]: {
    minHeight: props.minHeight,
  },
}));

export default function Wrap({
  display,
  children,
  height,
  minHeight,
  position,
  margin,
  padding,
  background,
  alignItems,
  overflow,
  onClick,
  className,
  style,
}) {
  return (
    <StyledWrap
      onClick={(e) => (onClick ? onClick(e) : null)}
      display={display}
      alignItems={alignItems}
      height={height}
      minHeight={minHeight}
      position={position}
      margin={margin}
      padding={padding}
      background={background}
      overflow={overflow}
      className={className}
      style={style}
    >
      {children}
    </StyledWrap>
  );
}

Wrap.propTypes = {
  position: PropTypes.string,
};

Wrap.defaultProps = {
  position: "relative",
};
