import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Wrap from "components/Wrap";

export const StyledHamburger = styled("div")({
  color: "#fff",
  paddingLeft: "12px",
  cursor: "pointer",
  lineHeight: 0,
  marginTop: -4,
  transition: "all 200ms ease-in-out",
  transform: "scaleX(1)",
  transformOrigin: "center center",

  "&:hover": {
    transform: "scaleX(1.3)",
  },
  [media("tablet")]: {
    paddingLeft: "24px",
  },
});

export const StyledClose = styled("div")(
  {
    color: "#fff",
    lineHeight: 0,
    marginLeft: "12px",
    cursor: "pointer",
    transition: "all 200ms ease-in-out",
    position: "fixed",
    transformOrigin: "center center",
    zIndex: 10000,

    [media("tablet")]: {
      marginLeft: "24px",
    },

    "&:hover": {
      transform: "translate(0, -100%) scale(1.1) rotate(90deg)",
    },
  },
  (props) => ({
    opacity: props.menuShown ? 1 : 0,
    transform: "translate(0, -100%)",
    pointerEvents: props.menuShown ? "all" : "none",
  })
);

export const WrapStyled = styled(Wrap)(
  {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    transition: "all 200ms ease-in-out",
    zIndex: 1000,
    overflow: "hidden",
    // background: "rgba(0, 0, 0, 0.9)",
  },
  (props) => ({
    opacity: props.menuShown ? 1 : 0,
    transform: props.menuShown ? "translate(0%)" : "translate(-50px)",
    pointerEvents: props.menuShown ? "all" : "none",
  })
);

export const MenuBgStyled = styled(Wrap)(
  {
    width: "100%",
    [media("tablet")]: {
      width: "33vw",
    },
    background: "black",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    height: "100%",
  },
  (props) => ({
    // [media(0, "tablet")]: {
    //   opacity: props.currentItemId ? 0 : 1,
    // },
  })
);

export const BackButtonStyled = styled("button")(
  {
    display: "flex",
    alignItems: "center",
    fontSize: ".8em",
    color: "#fff",
    backgroundColor: "transparent",
    border: 0,
    outline: "none",
    cursor: "pointer",

    // [media("tablet")]: {
    //   display: "none",
    // },
  },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
    marginBottom: props.theme.space,
  })
);

export const BackButtonTextStyled = styled("span")(
  {
    marginLeft: "1em",
    textTransform: "uppercase",
    fontWeight: 900,
  },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
  })
);

export const GridMenuStyled = styled("div")(
  {
    width: "100%",
    padding: "23vh 25px 0 25px",
    [media("tablet")]: {
      width: "33vw",
      padding: "200px 0 0 0",
    },
    [media("desktop")]: {
      padding: "30vh 0 0 0",
    },
  },
  // When children are given and we are on mobile devices => do not display the parent items
  (props) => ({
    [media("tabletFluid")]: {
      minHeight: props.fullHeight ? "100vh" : "80vh",
    },
  })
);

export const ParentGridItem = styled("div")(
  // When children are given and we are on mobile devices => do not display the parent items
  (props) =>
    props.isDisabled && {
      display: "none",

      [media("tablet")]: {
        display: "block",
      },
    }
);

export const SubmenuStyled = styled("div")(
  {
    width: "100vw",
    overflowY: "scroll",
    position: "absolute",
    right: 0,
    top: 30,
    height: "100vh",
    padding: "23vh 25px 100px 25px",
    [media("tablet")]: {
      width: "66vw",
      padding: "200px 0 0 0",
    },
    [media("desktop")]: {
      padding: "30vh 0 0 0",
    },
    transition: "all 500ms ease-out",
  },
  (props) => ({
    [media("tablet")]: {
      transform: props.currentItemId ? "translate(0)" : "translate(-20px)",
    },
    transitionDelay: props.currentItemId ? "150ms" : "0ms",
    transitionDuration: props.currentItemId ? "500ms" : "200ms",
    pointerEvents: props.currentItemId ? "all" : "none",
    opacity: props.currentItemId ? 1 : 0,
  })
);
