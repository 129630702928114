import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'utils/emotion';
import { toPx } from 'utils/strings';

import { GridContext } from 'components/Grid';

import themeDefault from 'components/Layout/themes/default';

import WrapGrid from 'components/WrapGrid';

const StyledGrid = styled('div')(
  {
    display: 'flex',
    flex: '0 0 auto',
    // no width instead of 100% --> https://stackoverflow.com/questions/15733294/why-negative-right-margin-not-work
  },
  props => ({
    flexWrap: props.wrapReverse ? 'wrap-reverse' : 'wrap',
    position: props.position,
    minHeight: props.minHeight,
    height: props.height,
    flexDirection: props.direction,
    flexBasis:
      (props.isNested && `calc(100% + ${toPx(props.theme.gridSpaceGutter)})`) ||
      props.flexBasis,
    marginLeft: `-${props.gutter / 2}px`,
    marginRight: `-${props.gutter / 2}px`,
    alignItems: props.alignitems,
    justifyContent: props.justifyContent,
    paddingTop: props.paddingTop,
    padding: props.padding,
    background: props.background,
    ':empty': {
      display: 'none',
    },
  }),
);

export default function Grid({
  column,
  row,
  children,
  gutter,
  width,
  height,
  minHeight,
  left,
  center,
  right,
  top,
  middle,
  bottom,
  between,
  around,
  reverse,
  padding,
  paddingTop,
  position,
  isNestedForce,
  background,
  className,
  wrapReverse,
}) {
  const { isNested } = useContext(GridContext);

  const direction = {
    column: 'column',
    row: 'row',
    reverse: `${(column && 'column') || (row && 'row')}-reverse`,
  };

  const alignitems = {
    top: 'flex-start',
    middle: 'center',
    bottom: 'flex-end',
  };

  const justifyContent = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
    between: 'space-between',
    around: 'space-around',
  };

  return (
    // Grids within a grid are nested (`isNested = true`) - see `GridContext.js` for first `Grid`
    <GridContext.Provider value={{ gutter, isNested: true }}>
      <WrapGrid
        isNested={isNestedForce || isNested}
        width={width}
        height={height}
        gutter={gutter}
        background={background}
      >
        <StyledGrid
          isNested={isNestedForce || isNested}
          background={background}
          gutter={gutter}
          height={height}
          minHeight={minHeight}
          paddingTop={paddingTop}
          padding={padding}
          position={position}
          className={className}
          wrapReverse={wrapReverse}
          alignitems={
            alignitems[
              (top && 'top') || (middle && 'middle') || (bottom && 'bottom')
            ]
          }
          justifyContent={
            justifyContent[
              (left && 'left') ||
                (center && 'center') ||
                (right && 'right') ||
                (around && 'around') ||
                (between && 'between')
            ]
          }
          direction={
            direction[
              (reverse && 'reverse') || (column && 'column') || (row && 'row')
            ]
          }
        >
          {children}
        </StyledGrid>
      </WrapGrid>
    </GridContext.Provider>
  );
}

Grid.propTypes = {
  gutter: PropTypes.number,
  left: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
  middle: PropTypes.bool,
  bottom: PropTypes.bool,
  between: PropTypes.bool,
  around: PropTypes.bool,
  reverse: PropTypes.bool,
  position: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isNestedForce: PropTypes.bool,
  row: PropTypes.bool,
};

Grid.defaultProps = {
  gutter: themeDefault.gridSpaceGutter,
  left: false,
  center: false,
  right: false,
  top: false,
  middle: false,
  bottom: false,
  between: false,
  around: false,
  reverse: false,
  position: 'relative',
  height: '100%',
  isNestedForce: false,
  row: true,
};

// TODO: ~DH
// 1. Unified Fractions (which means you can set a griditem width over the parent, maybe use context ?)
