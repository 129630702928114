import themeDefault from 'components/Layout/themes/default'
import { Global, css } from '@emotion/react'

/* eslint no-unused-expressions: 0 */

export default function GlobalStyle({}) {
  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }

        html {
          font-family: ${themeDefault.fontSecondary};
          color: ${themeDefault.colorPrimary};
        }

        body {
          background: ${themeDefault.bodyBackground};
          font-size: 15px;
          line-height: 1.5;
          overflow-y: scroll;
          -webkit-font-smoothing: antialiased;
        }

        ul {
          list-style-position: inside;
        }

        *::-webkit-scrollbar {
          width: ${themeDefault.scrollBarWidth};
        }

        *::-webkit-scrollbar-track {
          background: ${themeDefault.scrollBarTrack};
        }

        *::-webkit-scrollbar-thumb {
          background-color: ${themeDefault.scrollBarThumb};
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          a {
            color: inherit !important;
          }
        }

        a {
          color: inherit;
          font-size: inherit;
          text-decoration: none;
        }

        .scale-x-left {
          animation-name: scale;
          width: 100%;
          height: 100%;
          position: absolute;
          display: block;
          transform-origin: 0 50%;
          animation-fill-mode: both;
        }

        .scale-x-right {
          animation-name: scale;
          width: 100%;
          height: 100%;
          position: absolute;
          display: block;
          transform-origin: 100% 50%;
          animation-fill-mode: both;
        }

        @keyframes scale {
          0% {
            transform: scaleX(0);
          }
          100% {
            transform: scaleX(1);
          }
        }
      `}
    />
  )
}
