import { styled } from 'utils/emotion';
import Link from 'components/Link';
import media from 'utils/mediaqueries';
import themeDefault from 'components/Layout/themes/default';

export const NavHeaderStyled = styled('div')({});
export const NavHeaderItemStyled = styled(Link)({
  cursor: 'pointer',
  color: '#fff',
  marginRight: '12px',
  opacity: '0.5',
  fontFamily: themeDefault.fontSecondary,
  letterSpacing: '0.8px',
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: '700',

  '&:hover': {
    color: '#fff',
    opacity: '1',
  },

  [media('laptop')]: {
    marginRight: 24,
  },
});
