import React from 'react';
import PropTypes from 'prop-types';

import { styled } from 'utils/emotion';

const UnorderedListStyled = styled('ul')({}, props => ({
  color: props.theme.colorPrimary,
}));

const OrderedListStyled = styled('ol')({}, props => ({
  color: props.theme.colorPrimary,
}));

export default function RedactorList({ children, element, className }) {
  const ComponentList = {
    ul: UnorderedListStyled,
    ol: OrderedListStyled,
  };
  const Component = ComponentList[element];

  return <Component className={className}>{children}</Component>;
}

RedactorList.propTypes = {
  element: PropTypes.string,
};

RedactorList.defaultProps = {
  element: 'ul',
};
