import React from 'react';
import { graphql } from 'gatsby';
import ClubLocation from './TeaserMultiClubLocation';
import { TeaserMultiClubStyled, ButtonStyled } from './styles';

const TeaserMultiClub = props => {
  const { title, location, link } = props;

  return (
    <TeaserMultiClubStyled>
      <ButtonStyled
        to={link[0].fullUri}
        icon="arrowRight"
        type="bold"
        size="large"
        subline={<ClubLocation location={location} />}
        uppercase
        stripped
        forceButtonTextShrink
      >
        {title}
      </ButtonStyled>
    </TeaserMultiClubStyled>
  );
};

TeaserMultiClub.propTypes = {};

TeaserMultiClub.defaultProps = {};

export default TeaserMultiClub;

export const query = graphql`
  fragment NavMainMultiTeaserClubQuery on Craft_NavMainMultiTeaserClubsNavMainMultiTeaserClub {
    id
    title: navMainMultiTeaserClubTitle
    location: navMainMultiTeaserClubLocation {
      id
      ... on Craft_ClubLocationsCategory {
        locationAddress
        locationZip
        locationCity {
          id
        }
      }
    }
    link: navMainMultiTeaserClubLink {
      id
      fullUri
    }
  }
`;
