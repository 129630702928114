import React from 'react'
import PropTypes from 'prop-types'

import { withTheme } from 'utils/emotion'

import SwitchTheme from 'components/SwitchTheme'
import NullWrap from 'components/NullWrap'
import Link from 'components/Link'

import {
  StyledButton,
  StyledButtonText,
  StyledButtonIconLeft,
  StyledButtonIconRight,
  StyledButtonSubline,
  StyledButtonContent,
} from './ButtonStyled'

function Button({
  to,
  themeName,
  className,
  children,
  icon,
  iconLeft,
  iconAnimate,
  iconMargin,
  type,
  target,
  size,
  stripped,
  filled,
  uppercase,
  disabled,
  stretch,
  back,
  subline,
  forceButtonTextShrink,
  textAlign,
  margin,
  theme,
  onClick,
  isNotLink,
  style,
}) {
  // Adds wrapper component if button has subline
  const WrapperComponent = subline ? StyledButtonContent : NullWrap
  const SwitchThemeButton =
    themeName || themeName === undefined ? SwitchTheme : NullWrap

  if (themeName === 'transparent') {
    console.warn(
      `transparent theme passing to button component is deprecated, use 'stripped' prop instead!:
      >> ${children} <<`
    )
  }

  // Backbutton untested probably not stable ~DH
  const isClient = typeof window === 'object'
  const url = isClient ? window.location.href : '/'
  const urlRaw =
    url.slice(-1) === '/' && url.length > 1
      ? url.substring(0, url.length - 1)
      : url
  const currentItem = urlRaw.split('/').pop()
  // get baseUrl from any environment
  const pathArray = urlRaw.split('/')
  const protocol = pathArray[0]
  const host = pathArray[2]
  const baseUrl = `${protocol}//${host}`
  const stripCurrentItem = url.replace(baseUrl, '').replace(currentItem, '')
  const backUrl =
    stripCurrentItem.slice(-1) === '/' && stripCurrentItem.length > 1
      ? stripCurrentItem.substring(0, stripCurrentItem.length - 1)
      : stripCurrentItem

  return (
    <SwitchThemeButton name={theme?.inherit || themeName}>
      <StyledButton
        to={back ? backUrl : to}
        themeParent={theme?.name}
        themeName={themeName}
        buttonType={type}
        stripped={stripped}
        filled={filled}
        buttonSize={size}
        className={className}
        uppercase={uppercase}
        icon={icon}
        iconLeft={iconLeft}
        iconAnimate={iconAnimate}
        stretch={stretch}
        disabled={disabled}
        target={target}
        subline={subline}
        textAlign={textAlign}
        margin={margin}
        onClick={(e) => {
          isNotLink && e && e.preventDefault()
          if (onClick) onClick()
        }}
        style={style}>
        <WrapperComponent>
          {icon && iconLeft && (
            <StyledButtonIconLeft
              type={icon}
              buttonSize={size}
              iconMargin={iconMargin}
            />
          )}
          <StyledButtonText forceButtonTextShrink={forceButtonTextShrink}>
            {children}
          </StyledButtonText>
          {icon && !iconLeft && (
            <StyledButtonIconRight
              type={icon}
              buttonSize={size}
              iconMargin={iconMargin}
            />
          )}
        </WrapperComponent>
        {subline && <StyledButtonSubline>{subline}</StyledButtonSubline>}
      </StyledButton>
    </SwitchThemeButton>
  )
}

Button.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  target: PropTypes.string,
  stripped: PropTypes.bool,
  themeName: PropTypes.string,
  textAlign: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uppercase: PropTypes.bool,
  disabled: PropTypes.bool,
  stretch: PropTypes.bool,
  iconLeft: PropTypes.bool,
  iconAnimate: PropTypes.bool,
  iconMargin: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  forceButtonTextShrink: PropTypes.bool,
  isNotLink: PropTypes.bool,
}

Button.defaultProps = {
  to: '/',
  icon: null,
  target: '_top',
  stripped: false,
  textAlign: 'left',
  margin: '0 0 36px',
  themeName: null, // Do not change this ~DH
  uppercase: true,
  disabled: false,
  stretch: false,
  iconLeft: false,
  iconAnimate: true,
  iconMargin: false,
  forceButtonTextShrink: false,
  isNotLink: false,
}

export default withTheme(Button)
