import React from "react";

import media from "utils/mediaqueries";
import { css, styled } from "utils/emotion";

import Link from "components/Link";
import Icon from "components/Icon";

const StyledLogo = styled("div")({
  paddingLeft: "12px",

  [media("mobile", "tablet")]: {
    maxWidth: "140px"
  },
  [media("tabletFluid")]: {
    maxWidth: "160px"
  },
  [media("laptop")]: {
    maxWidth: "280px"
  },
  [media("tablet")]: {
    paddingLeft: "24px"
  }
});

export default function Logo({ to }) {
  return (
    <StyledLogo>
      <Link to={to}>
        <Icon type="logoEvo" />
      </Link>
    </StyledLogo>
  );
}
