import React from "react"
import PropTypes from "prop-types"
import { styled } from "utils/emotion"

import themeDefault from "components/Layout/themes/default"
import Null from "components/Null"

import * as Icons from "./Icons"
import { CloseBig } from "./Icons"

const StyledSvg = styled("svg")({
  maxWidth: "100%",
})

export default function Icon({
  type,
  width,
  height,
  viewBox,
  preserveAspectRatio,
  fillPrimary,
  fillSecondary,
  fillTertiary,
  currentColor,
  className,
  style,
}) {
  const iconMap = {
    phone: {
      icon: Icons.Phone,
      props: {
        width: "23.756",
        height: "44.118",
        viewBox: "0 0 23.756 44.118",
      },
    },
    glass: {
      icon: Icons.Glass,
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
    },
    medal: {
      icon: Icons.Medal,
      props: {
        width: "25.415",
        height: "46.385",
        viewBox: "0 0 25.415 46.385",
      },
    },
    tiles: {
      icon: Icons.Tiles,
      props: {
        width: "37.2",
        height: "41.3",
        viewBox: "0 0 37.2 41.3",
      },
    },
    instagram: {
      icon: Icons.Instagram,
      props: {},
    },
    clock: {
      icon: Icons.Clock,
      props: {
        width: "35.2",
        height: "41.2",
        viewBox: "0 0 41.2 41.2",
      },
    },
    dumbbell: {
      icon: Icons.Dumbbell,
      props: {
        width: "37.885",
        height: "30.547",
        viewBox: "0 0 37.885 30.547",
      },
    },
    europeStars: {
      icon: Icons.EuropeStars,
      props: {
        width: "37.2",
        height: "36.977",
        viewBox: "0 0 37.2 36.977",
      },
    },
    logoEvo_2: {
      icon: Icons.LogoEVO_2,
      props: {
        width: "55.222",
        height: "18",
        viewBox: "0 0 55.222 15.873",
      },
    },
    file: {
      icon: Icons.File,
      props: {
        width: "36px",
        height: "36px",
        viewBox: "0 0 34.133 43.496",
      },
    },
    people: {
      icon: Icons.People,
      props: {
        viewBox: "0 0 45.887 37.154",
        width: "45.887",
        height: "37.154",
      },
    },
    wallet: {
      icon: Icons.Wallet,
      props: {
        viewBox: "0 0 41.3 38.098",
        width: "41.3",
        height: "38.098",
      },
    },
    socialFacebook: {
      icon: Icons.SocialFacebook,
      props: {
        viewBox: "0 0 17 17",
      },
    },
    closeBig: {
      icon: CloseBig,
      props: {
        height: "24",
        width: "24",
        viewBox: "0 0 10 10",
      },
    },
    checkmark: {
      icon: Icons.Checkmark,
      props: {
        viewBox: "0 4 24 20",
      },
    },
    socialInstagram: {
      icon: Icons.SocialInstagram,
      props: {
        viewBox: "0 0 17 17",
      },
    },
    logoEvo: {
      icon: Icons.LogoEvo,
      props: {
        viewBox: "0 0 231 31.021",
        width: "254",
        height: "35",
      },
    },
    hamburger: {
      icon: Icons.Hamburger,
      props: {
        viewBox: "0 0 21 14",
      },
    },
    arrowLeft: {
      icon: Icons.ArrowLeft,
      props: {
        viewBox: "0 0 35 12.372",
        width: "35",
      },
    },
    play: {
      icon: Icons.Play,
      props: {
        viewBox: "0 0 8.7 12.4",
        width: "13",
      },
    },
    arrowRight: {
      icon: Icons.ArrowRight,
      props: {
        viewBox: "0 0 35 12.372",
        width: "35",
      },
    },
    arrowDown: {
      icon: Icons.ArrowDown,
      props: {
        viewBox: "0 0 12.371 35",
        width: "12.371",
      },
    },
    arrowDownShort: {
      icon: Icons.arrowDownShort,
      props: {
        viewBox: "0 0 12.4 12.4",
        width: "12.4",
      },
    },
    keycard: {
      icon: Icons.KeyCard,
      props: {
        viewBox: "0 0 100 100",
        width: "50",
      },
    },
    wifi: {
      icon: Icons.Wifi,
      props: {
        viewBox: "0 0 100 100",
        width: "40",
      },
    },
    expert: {
      icon: Icons.Expert,
      props: {
        viewBox: "0 0 40 40",
        width: "40",
      },
    },
    profile: {
      icon: Icons.Profile,
      props: {
        viewBox: "0 0 40 40",
        width: "50",
      },
    },
  }

  // Avoid obsolete nesting within iconMap if there are no settings
  const IconContent = iconMap?.[type]?.icon || iconMap?.[type]

  return IconContent ? (
    <StyledSvg
      xmlns='http://www.w3.org/2000/svg'
      data-icon={type}
      width={iconMap?.[type]?.props?.width || width || "24px"}
      height={iconMap?.[type]?.props?.height || height || "24px"}
      viewBox={viewBox || iconMap?.[type]?.props?.viewBox || "0 0 24 24"}
      preserveAspectRatio={preserveAspectRatio}
      className={className}
      style={style}
    >
      <IconContent
        fillPrimary={currentColor ? "currentColor" : fillPrimary}
        fillSecondary={currentColor ? "currentColor" : fillSecondary}
        fillTertiary={fillTertiary}
      />
    </StyledSvg>
  ) : (
    // Fallback if the iconMap doesnt match right so that the app does not crash
    <Null
      warning={console.warn(`Error occured while loading your Icon:'${type}'`)}
    />
  )
}

/* eslint-disable react/require-default-props */
// We set the default values/props for height, width and viewbox within component as fallback
Icon.propTypes = {
  type: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  preserveAspectRatio: PropTypes.string,
  fillPrimary: PropTypes.string,
  fillSecondary: PropTypes.string,
  fillTertiary: PropTypes.string,
  currentColor: PropTypes.bool,
}

Icon.defaultProps = {
  type: "arrowLeft",
  preserveAspectRatio: "xMidYMid meet",
  currentColor: true,
  fillPrimary: themeDefault.colorPrimary,
  fillSecondary: themeDefault.colorSecondary,
  fillTertiary: themeDefault.colorTertiary,
}
