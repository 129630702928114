import { css as cssEmotion, jsx as cxEmotion } from '@emotion/react'
import styledEmotion from '@emotion/styled'
import { withTheme as withThemeEmotion } from '@emotion/react'

// we use the utils/emotion file as single source of truth transition file to make future updates easier
export const css = cssEmotion
export const cx = cxEmotion
export const withTheme = withThemeEmotion

export const styled = styledEmotion
