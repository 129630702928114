import { keyframes } from "@emotion/react";

import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

import Link from "components/Link";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const blink = keyframes`
  from, to {
    background: transparent;
  }
  50% {
    background: #ed8812;
  }
`;

const scaleFade = keyframes`
  0% {
    height: 30px;
    background: #ed8812;
  }
  50% {
    height: 30px;
    width: 100%;
  }
  75% {
    height: 30px;
    width: 100%;
  }
  100% {
    top: 'auto';
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
  }
`;

export const NavigationWrapStyled = styled("div")(
  {
    height: "auto",
    transition: "all 500ms ease-out",
    [media("mobile", "tablet")]: {
      width: "100%",
      // overflow: 'hidden',
    },
    [media("tabletFluid")]: {
      position: "relative",
      height: "60px",
      width: "100%",
    },
  },
  (props) => ({
    opacity: props.menuShown ? 1 : 0,
    transform: props.menuShown ? "translateY(0)" : "translateY(10px)",
    transitionDelay: `${props.index * 75}ms`,
  })
);

export const NavigationPositionerStyled = styled("div")({
  [media("tabletFluid")]: {
    position: "absolute",
    left: 20,
    top: 0,
    width: "100%",
    maxWidth: "calc(33vw - 90px)",
    height: "100%",
    overflow: "hidden",

    [media("tabletFluid")]: {
      // whiteSpace: "nowrap",
    },
  },
});

export const NavigationHolderStyled = styled("div")({
  width: "100%",
  height: "auto",
  [media("tabletFluid")]: {
    position: "absolute",
    right: 0,
    top: 0,
    // width: "333px", // this needs to be a calculation
  },
  [media("laptop")]: {
    width: "263px", // this needs to be a calculation
  },
  [media("desktop")]: {
    width: "357px", // this needs to be a calculation
  },
});

export const ItemWrapStyled = styled("h3")({}, (props) => ({
  marginBottom: props.theme.space,
  position: "relative",
  display: "inline-block",
  whiteSpace: "normal",
  height: 42,
  lineHeight: 1,

  ":before": props.hasAnimation && {
    content: '""',
    position: "absolute",
    top: "auto",
    left: 0,
    background: "#ed8812",
    height: 30,
    width: 1,
    bottom: 0,
    zIndex: 2,
    animation: `${blink} 0.5s 0s ease-in-out 2, ${scaleFade} 1s 1s ease-in-out forwards`,
  },
}));

export const ItemStyled = styled("div")(
  {
    position: "relative",
    height: "100%",
    display: "block",
    fontSize: "22px",
    lineHeight: "32px",
    fontWeight: 900,
    letterSpacing: "3px",
    textTransform: "uppercase",
    textShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    opacity: "0.5",
    span: {
      cursor: "pointer",
    },
  },
  (props) => ({
    color: props.theme.colorPrimary,
    fontFamily: props.theme.fontFamilySecondary,
    ":before": {
      content: '""',
      position: "absolute",
      top: "calc(50% - 6px)",
      right: "100%",
      marginRight: "56px",
      backgroundColor: "#fff",
      height: "2px",
      width: 150,
      transition: "all 50ms ease-out",
      transitionDelay: props.isActive ? "0ms" : "75ms",
      transform: props.isActive ? "scaleX(1)" : "scaleX(0)",
      transformOrigin: "left center",
    },
    ":after": {
      content: '""',
      position: "absolute",
      top: "calc(50% - 6px)",
      left: "100%",
      marginLeft: "56px",
      backgroundColor: "#fff",
      height: "2px",
      width: 150,
      transition: "all 50ms ease-out",
      transitionDelay: props.isActive ? "75ms" : "0ms",
      transform: props.isActive ? "scaleX(1)" : "scaleX(0)",
      transformOrigin: "left center",
    },
    opacity: (props.isActive || props.isParentActive) && 1,
    animation: props.hasAnimation && `${fadeIn} 3s linear 1`,
  })
);

export const LinkStyled = styled(Link)({});
