import React from "react";
import entDecode from "ent/decode";
import striptags from "striptags";
import HtmlToReact from "html-to-react";

import RedactorHeadline from "components/RedactorHeadline";
import RedactorText from "components/RedactorText";
import RedactorLink from "components/RedactorLink";
import RedactorList from "components/RedactorList";
import RedactorListItem from "components/RedactorListItem";
import RedactorBlockquote from "components/RedactorBlockquote";

const tags = {
  basic: ["a", "strong", "b", "em", "i", "br"],
  advanced: [
    "ul",
    "ol",
    "li",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "iframe",
    "blockquote",
  ],
  auxiliary: ["table", "tbody", "tr", "td", "th", "caption"],
};

const allTags = [...tags.basic, ...tags.advanced];
const allTagsWithAuxiliary = [...allTags, ...tags.auxiliary];

export function limitCharacters(string, limit = 160, separator = " ") {
  if (!string || string.length <= limit) return string;
  return string.substr(0, string.lastIndexOf(separator, limit));
}

export function removeBackendUrl(url) {
  return url.replace(process.env.GATSBY_URL_BASE, "");
}

function processContentWrapper(_, children) {
  return (
    <div key={0} data-cms>
      {children}
    </div>
  );
}

function processParagraphNode(node, children, index) {
  const className = node.attribs.class || null;

  return (
    <RedactorText key={index} element={node.name} className={className}>
      {children}
    </RedactorText>
  );
}

function processUlNode(node, children, index) {
  const className = node.attribs.class || null;

  return (
    <RedactorList key={index} element={node.name} className={className}>
      {children}
    </RedactorList>
  );
}

function processOlNode(node, children, index) {
  const className = node.attribs.class || null;

  return (
    <RedactorList key={index} element={node.name} className={className}>
      {children}
    </RedactorList>
  );
}

function processLiNode(node, children, index) {
  const className = node.attribs.class || null;

  return (
    <RedactorListItem key={index} element={node.name} className={className}>
      {children}
    </RedactorListItem>
  );
}

function processBlockquoteNode(node, children, index) {
  const className = node.attribs.class || null;

  return (
    <RedactorBlockquote key={index} element={node.name} className={className}>
      {children}
    </RedactorBlockquote>
  );
}

function processHeadlineNode(node, children, index) {
  const id = node.attribs.id || undefined;
  const name = node.attribs.name || undefined;
  const className = node.attribs.class || null;

  return (
    <RedactorHeadline key={index} id={id} name={name} className={className}>
      {children}
    </RedactorHeadline>
  );
}

function processLinkNode(node, children, index) {
  const href = removeBackendUrl(node.attribs.href) || null;
  const target = node.attribs.target || null;
  const rel = node.attribs.rel || null;
  const className = node.attribs.class || null;

  return (
    <RedactorLink
      key={index}
      to={href}
      target={target}
      rel={rel}
      className={className}
    >
      {children}
    </RedactorLink>
  );
}

function processBoldNode(node, children, index) {
  return <strong key={index}>{children}</strong>;
}

function processItalicNode(node, children, index) {
  return <em key={index}>{children}</em>;
}

const isValidNode = (node) =>
  ~tags.basic.indexOf(node.name) ||
  ~tags.advanced.indexOf(node.name) ||
  ~tags.auxiliary.indexOf(node.name) ||
  node.type === "text";

function parseHtml(html) {
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  const processingInstructions = [
    {
      shouldProcessNode: (node) => node.name === "cwrapper",
      processNode: processContentWrapper,
    },
    {
      shouldProcessNode: (node) => node.name === "p",
      processNode: processParagraphNode,
    },
    {
      shouldProcessNode: (node) => node.name === "blockquote",
      processNode: processBlockquoteNode,
    },
    {
      shouldProcessNode: (node) => node.name === "a",
      processNode: processLinkNode,
    },
    // {
    //   shouldProcessNode: node => node.name === 'li',
    //   processNode: processLiNode,
    // },
    {
      shouldProcessNode: (node) => node.name === "ul",
      processNode: processUlNode,
    },
    {
      shouldProcessNode: (node) => node.name === "ol",
      processNode: processOlNode,
    },
    {
      shouldProcessNode: (node) => node.name === "b",
      processNode: processBoldNode,
    },
    {
      shouldProcessNode: (node) => node.name === "strong",
      processNode: processBoldNode,
    },
    {
      shouldProcessNode: (node) => node.name === "i",
      processNode: processItalicNode,
    },
    {
      shouldProcessNode: (node) => node.name && node.name.match(/h[1-6]/),
      processNode: processHeadlineNode,
    },
    {
      shouldProcessNode: isValidNode,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];
  const htmlToReactParser = new HtmlToReact.Parser(React);
  return htmlToReactParser.parseWithInstructions(
    `<cwrapper>${html}</cwrapper>`,
    () => true,
    processingInstructions
  );
}

export function removeScripts(text) {
  return text.replace(/<script([\S\s]*?)<\/script>/gi, "");
}

export function removeDangerousTags(html) {
  html = removeScripts(html);
  return html;
}

export function parseAsText(html) {
  if (!html) return null;
  html = String(html);
  html = removeDangerousTags(html);
  const parsed = entDecode(striptags(html));
  return parsed;
}

export function parseAsContent(html, { enableAuxiliaryTags = false } = {}) {
  if (!html) return null;
  html = String(html).replace(/(\r\n|\r|\n)/g, "");
  html = removeScripts(html);
  html = striptags(html, enableAuxiliaryTags ? allTagsWithAuxiliary : allTags);
  return parseHtml(html);
}
