import styled from "@emotion/styled";
import media from "utils/mediaqueries";

export const TeaserSingleStyled = styled("div")({
  display: "block",
  width: "100%",

  [media("tablet")]: {
    paddingRight: 50,
  },
});
