import React, { useState, useContext, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Icon from "components/Icon";
import Item from "components/NavigationMain/Item";
import Overlay from "components/Overlay";
import SingleTeaser from "components/NavigationMain/TeaserSingle";
import MultiTeaser from "components/NavigationMain/TeaserMulti";
import MultiTeaserClub from "components/NavigationMain/TeaserMultiClub";
import { LayoutContext } from "components/Layout/Layout.js";
import NavigationBgImage from "components/NavigationBgImage";

import {
  ParentGridItem,
  BackButtonStyled,
  BackButtonTextStyled,
  GridMenuStyled,
  WrapStyled,
  SubmenuStyled,
  StyledHamburger,
  StyledClose,
  MenuBgStyled,
} from "./styles";

export default function NavigationMain({ siteId }) {
  const [currentItemId, setCurrentItemId] = useState(null);
  const [itemChildrenVisible, setItemChildrenVisible] = useState(true);
  const { menuOpen, setMenuOpen } = useContext(LayoutContext);
  const [menuShown, showMenu] = useState(menuOpen);
  let items = null;

  if (siteId) {
    const staticData = useStaticQuery(graphql`
      query GlobalNavigationMainoQueryStatic {
        craft {
          signupDe: globals(siteId: 1) {
            ...GlobalCtaSignUpQuery
          }

          signupEn: globals(siteId: 2) {
            ...GlobalCtaSignUpQuery
          }

          # NavigationMain
          navigationMainItemsDe: entries(siteId: 1, section: navMain) {
            ...NavigationMainQuery
          }

          navigationMainItemsEn: entries(siteId: 2, section: navMain) {
            ...NavigationMainQuery
          }
        }
      }
    `);

    const { signupDe, signupEn, navigationMainItemsDe, navigationMainItemsEn } =
      staticData?.craft;

    const signup = siteId === 1 ? signupDe : signupEn;
    items = siteId === 1 ? navigationMainItemsDe : navigationMainItemsEn;
  }

  /**
   * Returns item item slag from url
   * @return string | null
   */
  const getItemSlugFromUrl = () => {
    if (!window) {
      return null;
    }
    const slug = window.location.hash.replace("#", "");
    return slug;
  };

  /**
   * Shows item corresponding to the current url slug
   * @return null;
   */
  const showItemFromRoute = () => {
    const currentItemSlug = getItemSlugFromUrl();
    showItemWithSlug(currentItemSlug);
  };

  const getItemWithSlug = (witSlug, items) => {
    if (!items) {
      console.warn("[NavigationMain] No items exist!");
      return;
    }
    return items.find((item) => item.slug === witSlug);
  };

  /**
   * Shows item with passed slug.
   * @description if item with slug exist show it, else null
   * @return null;
   */
  const showItemWithSlug = (slug) => {
    if (!slug) {
      setCurrentItemId(null);
      setMenuOpen(false);
      showMenu(false);
      return;
    }
    const item = getItemWithSlug(slug, items);
    if (!item) {
      console.warn(`No Item with "${slug}" slug exist!`);
      setCurrentItemId(null);
      setMenuOpen(false);
      showMenu(false);
    } else {
      setCurrentItemId(item.id);
      setMenuOpen(true);
      showMenu(true);
    }
  };

  /**
   * Function:
   * Get full uri of an items direct link.
   */
  const getItemDirectLinkFullUri = (item) =>
    item.navMainDirectLink && item?.navMainDirectLink?.url;

  /**
   * Function:
   * Render teaser for item.
   */
  const renderItemChildren = (item) => {
    const teaserComponent = item.__typename.replace("Craft_NavMain", "");
    switch (teaserComponent) {
      case "SingleTeaser":
        return item.navMainSingleTeaser.map((teaser) => {
          return <SingleTeaser key={`${item.id}-${teaser.id}`} {...teaser} />;
        });
      case "MultiTeaser":
        return item.navMainMultiTeaser.map((teaser) => (
          <MultiTeaser key={`${item.id}-${teaser.id}`} {...teaser} />
        ));
      case "MultiTeaserClubs":
        return item.navMainMultiTeaserClubs.map((teaser) => (
          <MultiTeaserClub key={`${item.id}-${teaser.id}`} {...teaser} />
        ));
      default:
        return null;
    }
  };

  /**
   * Event:
   * Item click.
   */
  const handleItemClick = (id, slug) => {
    if (typeof window !== "undefined") {
      const encodedUrl = encodeURIComponent(slug);
      // append the current item slug to the url
      window.history.replaceState(null, "", `#${encodedUrl}`);
      const path = `${window.location.pathname}/${slug}`.replace("//", "/");
      showItemWithSlug(slug);
      if (typeof window !== "undefined") {
        if (typeof window.ga === "function") {
          window.ga("send", {
            hitType: "pageview",
            page: path,
          });
        }
      }
    }
  };

  /**
   * Event:
   * Back button click.
   */
  const handleBackButtonClick = () => {
    window.history.pushState(null, "", "/");
  };

  useEffect(() => {
    if (window.history) {
      // watch for the Logo click
      const pushState = window.history.pushState;
      window.history.pushState = function () {
        pushState.apply(window.history, arguments);
        showItemFromRoute();
      };
    }

    // shows item on page load
    showItemFromRoute();
  }, []);
  return (
    <>
      <StyledHamburger
        onClick={(e) => {
          showMenu(true);
        }}
      >
        <Icon type="hamburger" />
      </StyledHamburger>
      <StyledClose
        onClick={(e) => {
          handleItemClick(e, "", "");
          showMenu(false);
        }}
        menuShown={menuShown}
      >
        <Icon type="closeBig" />
      </StyledClose>

      <WrapStyled overflow="hidden" minHeight="100vh" menuShown={menuShown}>
        <Overlay />
        {currentItemId && (
          <NavigationBgImage items={items} currentItemId={currentItemId} />
        )}
        <MenuBgStyled currentItemId={currentItemId}>
          <Overlay />
        </MenuBgStyled>
        <GridMenuStyled fullHeight>
          <ParentGridItem
            style={{
              marginTop: "30px",
              marginBottom: "30px",
            }}
            isDisabled={currentItemId !== null}
          >
            {items &&
              items.map((item, index) => (
                <Item
                  menuShown={menuShown}
                  index={index}
                  id={item.id}
                  key={item.id}
                  slug={item.slug}
                  activeParent={currentItemId === null}
                  active={currentItemId === item.id}
                  onClick={(e, id, slug) => handleItemClick(e, id, slug)}
                  fullUri={getItemDirectLinkFullUri(item)}
                  title={item.title}
                  hasAnimation={item.navMainAnimation}
                />
              ))}
          </ParentGridItem>
        </GridMenuStyled>

        <SubmenuStyled currentItemId={currentItemId}>
          <BackButtonStyled
            onClick={(e) => {
              window.history.replaceState(null, "", `#`);
              setCurrentItemId(null);
            }}
          >
            <Icon type="arrowLeft" />
            <BackButtonTextStyled>Back</BackButtonTextStyled>
          </BackButtonStyled>
          {currentItemId && itemChildrenVisible
            ? renderItemChildren(
                items.find((item) => item.id === currentItemId)
              )
            : null}
        </SubmenuStyled>
      </WrapStyled>
    </>
  );
}

export const query = graphql`
  fragment NavigationMainQuery on Craft_EntryInterface {
    id
    slug
    title
    __typename
    # entry type: default
    ... on Craft_NavMainDefault {
      navMainDirectLink {
        url
        customText
      }
      navMainBgImage {
        ...CraftImageFluid
      }
      navMainAnimation
    }

    # entry type: single teaser
    ... on Craft_NavMainSingleTeaser {
      navMainSingleTeaser {
        ...NavMainSingleTeaserQuery
      }
      navMainBgImage {
        ...CraftImageFluid
      }
      navMainAnimation
    }

    # entry type: multi teaser
    ... on Craft_NavMainMultiTeaser {
      navMainMultiTeaser {
        ...NavMainMultiTeaserQuery
      }
      navMainBgImage {
        ...CraftImageFluid
      }
      navMainAnimation
    }

    # entry type: multi teaser (clubs)
    ... on Craft_NavMainMultiTeaserClubs {
      navMainMultiTeaserClubs {
        ...NavMainMultiTeaserClubQuery
      }
      navMainBgImage {
        ...CraftImageFluid
      }
      navMainAnimation
    }
  }

  fragment GlobalCtaSignUpQuery on Craft_GlobalsSet {
    ctaSignUp: ctaButtons {
      ctaSignUpTitle
      ctaSignUpText
      ctaSignUpLink {
        url
        text
        entry {
          title
          fullUri
        }
      }
      ctaTrialLink {
        url
        text
        entry {
          title
          fullUri
        }
      }
    }
  }
`;
