import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

export const ImageWrapperStyled = styled("div")(
  {
    // pointerEvents: "none",
    transition: "opacity 200ms ease-out",
    opacity: 1,
    backfaceVisibility: "hidden",
    zIndex: 0,
    position: "absolute",
    height: "100%",
    top: 0,
    right: 0,
    zIndex: 0,
    display: "none",
    width: "100vw",

    [media("tablet")]: {
      display: "block",
    },
    [media("desktop")]: {
      width: "67vw",
    },

    img: {
      opacity: "1 !important",
      transition: "all 500ms ease-out !important",
      height: "100vh !important",
    },
  },
  (props) => ({
    opacity: props.active ? 1 : 0,
  })
);
