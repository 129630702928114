import React from "react";
import PropTypes from "prop-types";
import {
  NavigationWrapStyled,
  NavigationPositionerStyled,
  NavigationHolderStyled,
  ItemWrapStyled,
  ItemStyled,
  LinkStyled,
} from "./styles";

export default function Item({
  id,
  title,
  fullUri,
  slug,
  onClick,
  active,
  activeParent,
  hasAnimation,
  menuShown,
  index,
}) {
  return (
    <NavigationWrapStyled menuShown={menuShown} index={index}>
      <NavigationPositionerStyled>
        <NavigationHolderStyled>
          <ItemWrapStyled hasAnimation={hasAnimation}>
            <ItemStyled
              isActive={active}
              isParentActive={activeParent}
              hasAnimation={hasAnimation}
            >
              {fullUri ? (
                <LinkStyled to={fullUri}>{title}</LinkStyled>
              ) : (
                <span
                  onClick={() => (onClick ? onClick(id, slug) : null)}
                  role="button"
                >
                  {title}
                </span>
              )}
            </ItemStyled>
          </ItemWrapStyled>
        </NavigationHolderStyled>
      </NavigationPositionerStyled>
    </NavigationWrapStyled>
  );
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  fullUri: PropTypes.string,
  active: PropTypes.bool,
  hasAnimation: PropTypes.bool,
};

Item.defaultProps = {
  fullUri: null,
  active: false,
  hasAnimation: true,
};
