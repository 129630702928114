import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

export const GridItemStyled = styled('div')(
  {
    flex: '0 0 auto',
    msFlex: '0 0 auto',
  },
  ({
    display,
    alignSelf,
    background,
    height,
    mobile,
    tablet,
    tabletFluid,
    laptop,
    desktop,
    gutter,
    order,
    textAlign,
    zIndex,
  }) => ({
    display,
    alignSelf,
    background,
    height,
    maxWidth: (mobile === '0%' && '100%') || mobile,
    flexBasis: (mobile === '0%' && 'auto') || mobile,
    [media('tablet')]: {
      maxWidth: (tablet === '0%' && '100%') || tablet,
      flexBasis: (tablet === '0%' && 'auto') || tablet,
    },
    [media('tabletFluid')]: {
      maxWidth: (tabletFluid === '0%' && '100%') || tabletFluid,
      flexBasis: (tabletFluid === '0%' && 'auto') || tabletFluid,
    },
    [media('laptop')]: {
      maxWidth: (laptop === '0%' && '100%') || laptop,
      flexBasis: (laptop === '0%' && 'auto') || laptop,
    },
    [media('desktop')]: {
      maxWidth: (desktop === '0%' && '100%') || desktop,
      flexBasis: (desktop === '0%' && 'auto') || desktop,
    },
    padding: `0 ${gutter / 2}px`,
    order,
    textAlign,
    zIndex,
  }),
);
