export const fonts = {
  primary: "Open Sans, sans-serif",
  secondary: "Montserrat, sans-serif"
};

export const colors = {
  black: "#000000",
  white: "#ffffff",

  greyLightest: "#A7A7A7",
  greyLighter: "#D8D8D8",
  greyLight: "#46515C",
  grey: "#404040",
  greyDark: "#1C2222",
  greyDarker: "#120f0b",

  greenLighter: "#C9DEDE",
  greenLight: "#00EAE3",
  green: "#02b0ab",
  greenDark: "#009B97",
  greenDarker: "#0A2B2B",
  greenDarkest: "#0D0D0D",

  blieLight: "#65b4c7",
  blue: "#015F76",
  blueDark: "#033845",
  blueDarker: "#111D29",
  blueDarkest: "#0f161d",

  orange: "#EA5A00",
  purple: "#9B0071"
};

export const gradients = {
  whiteToWhite: [colors.white, colors.white],
  whiteTriple: [colors.white, colors.white, colors.white],
  greenLightToGreenDark: [colors.green, colors.greenDark],
  greenLightToBlue: [colors.greenLight, colors.blue],
  greenDarkerToBlueDarker: [colors.greenDarker, colors.blueDarker],
  greenDarkestToBlueDark: [colors.greenDarkest, colors.blueDark],
  greenDarkestToBlueDarkest: [colors.greenDarkest, colors.blueDarkest],
  whiteToGreenLight: [colors.white, colors.greenLighter],
  greyDarkToGrey: [colors.greyDark, colors.grey],
  orangeToPurple: [colors.orange, colors.purple]
};

// The problem with numbers here is that 0 is handled as null which is
// problematic for gradient util function if someone knows better let me know ~DH
export const alphaMaps = {
  zeroToHundred: ["0", "1"],
  zeroHundredHundred: ["0", "1", "1"],
  hundredToZero: ["1", "0"],
  fiftyFifty: ["0.5", "0.5"],
  fiftyFifty: ["0.5", "0.5"],
  zeroToNinty: ["0", "0.9"],
  thirtyToNinty: ["0.3", "0.9"]
};

export const positionMaps = {
  zeroEightyHundred: ["0%", "80%", "100%"]
};

export const sizes = {
  spaceEight: 3,
  spaceQuarter: 6,
  spaceThird: 8,
  spaceHalf: 12,
  spaceThirds: 18,
  space: 24,
  spaceEighted: 32,
  spaceQuartered: 36,
  spaceDouble: 48,
  spaceDoubleEight: 56,
  spaceDoubleThirds: 64,
  spaceTriple: 72,
  spaceQuad: 96,
  spaceFive: 120,
  spaceTen: 240,
  spaceFifteen: 360,
  spaceTwenty: 480,
  spaceThirty: 720,
  spaceFourty: 960,
  spaceFifty: 1200,
  spaceSixty: 1440,
  spaceHundredTwenty: 2880
};
