import { styled } from 'utils/emotion';
import Link from 'components/Link';
import media from 'utils/mediaqueries';

export const LinkStyled = styled(Link)({
  cursor: 'pointer',
  color: 'inherit',
  marginRight: '12px',
  opacity: '0.5',
  fontFamily: 'inherit',
  letterSpacing: '0.8px',
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: '700',

  '&:hover': {
    color: 'inherit',
    opacity: '1',
  },

  [media('laptop')]: {
    marginRight: 24,
  },
});
