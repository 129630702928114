import React, { useEffect, useState, createContext } from "react";
import PropTypes from "prop-types";

import loadable from "@loadable/component";
import Header from "components/Header";
// import Footer from 'components/Footer'
// import FooterSection from 'components/FooterSection'
import Favicons from "components/Favicons";
import MetaLanguage from "components/MetaLanguage";
import SwitchTheme from "components/SwitchTheme";
// import CookieInfo from 'components/CookieInfo'
import { graphql, useStaticQuery } from "gatsby";

import "components/ImageGatsbyCraft/imageQueries";
import GlobalStyle from "./globalStyles";

const Footer = loadable(() => import("../Footer"));
const FooterSection = loadable(() => import("../FooterSection"));

export const LayoutContext = createContext({});
export default function Wrapper(props) {
  const [isWindowLoaded, setIsWindowLoaded] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined" && isWindowLoaded === false) {
      setIsWindowLoaded(true);
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "optimize.activate" });
  }, []);

  const {
    children,
    location,
    data,
    pageContext: {
      siteId,
      alternateEntries,
      language,
      pageLayout,
      isHome,
      pageHideMenu,
      pageHideCtas,
    },
  } = props;

  const staticData = useStaticQuery(graphql`
    query LayoutGlobalQueryStatic {
      craft {
        # Globals
        globalsDe: globals(siteId: 1) {
          ...GlobalNavigationSocialQuery
          ...GlobalCtaSignUpQuery
          ...GlobalFooterSectionQuery
          ...GlobalCookieInfoQuery
          ...GlobalCtaReadMoreQuery
          ...GlobalSeoQuery
        }

        globalsEn: globals(siteId: 2) {
          ...GlobalNavigationSocialQuery
          ...GlobalCtaSignUpQuery
          ...GlobalFooterSectionQuery
          ...GlobalCookieInfoQuery
          ...GlobalCtaReadMoreQuery
          ...GlobalSeoQuery
        }
      }
    }
  `);

  const { globalsDe, globalsEn } = staticData?.craft;
  const globals = siteId === 1 ? globalsDe : globalsEn;

  // Navigation items.
  // @todo: this looks ugly, but there seems to be no pretty way to actually deconstruct the data with fallbacks?
  // @todo: please fix if you know it better.
  const navigationItems = {
    header: data?.craft?.navigationHeaderItems || [],
    footer: data?.craft?.navigationFooterItems || [],
    social: globals.navigationSocial?.navigationSocialItems || [],
  };

  // Hotfix for setting baseUrl in global components ~DH
  const currentBaseUrl = siteId === 2 ? "/en" : "/";

  // Call to action buttons.
  const ctas = {
    signUp: globals.ctaSignUp,
  };

  return (
    <SwitchTheme>
      <LayoutContext.Provider value={{ menuOpen, setMenuOpen }}>
        <GlobalStyle />
        <Favicons />
        <MetaLanguage language={language} alternateEntries={alternateEntries} />
        <Header
          navigationItems={navigationItems.header}
          pathname={location?.pathname}
          currentSiteId={siteId}
          alternateEntries={alternateEntries}
          pageHideMenu={pageHideMenu}
          pageHideCtas={pageHideCtas}
          signUp={ctas.signUp}
          currentBaseUrl={currentBaseUrl}
          isHome={isHome}
          siteId={siteId}
        />
        {children}
        {pageLayout !== "layoutFullscreen" && (
          <FooterSection
            {...globals.footerSection}
            currentBaseUrl={currentBaseUrl}
          />
        )}
        <Footer
          pageLayout={pageLayout}
          navigationFooterItems={navigationItems.footer}
          navigationSocialItems={navigationItems.social}
        />
        {/* <CookieInfo
          buttonLabel={globals.dataPrivacy?.dataPrivacyAcceptLabel}
          content={globals.dataPrivacy?.dataPrivacyText?.content}
        /> */}
      </LayoutContext.Provider>
    </SwitchTheme>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};
