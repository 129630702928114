import { styled } from 'utils/emotion';
import Link from 'components/Link';

export const RedactorLinkStyled = styled(Link)(
  {
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '.9em',
  },
  props => ({
    color: props.theme.linkColor,
    borderBottom: `2px solid ${props.theme.linkBorderColor}`,

    '&:hover, &:active, &:focus': {
      color: props.theme.linkColorHover,
      borderBottom: `2px solid ${props.theme.linkBorderColorHover}`,
    },
  }),
);
