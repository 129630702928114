const vars = require("components/Layout/variables");
const utils = require("utils/styles");

module.exports = {
  name: "default",
  inherit: null,

  colorPrimary: vars.colors.white,
  colorPrimaryHover: vars.colors.white,
  colorSecondary: vars.colors.black,
  colorSecondaryHover: vars.colors.black,
  colorTertiary: vars.colors.green,
  colorTertiaryHover: vars.colors.green,

  blue: vars.colors.blue,
  greenDarker: vars.colors.greenDark,

  background: vars.colors.greyDarker,
  backgroundPrimary: vars.colors.greyDarker,
  backgroundPrimaryHover: vars.colors.greyDarker,
  backgroundSecondary: vars.colors.white,
  backgroundSecondaryHover: vars.colors.white,
  backgroundTertiary: vars.colors.green,
  backgroundTertiaryHover: vars.colors.green,

  backgroundInstagram: vars.colors.greyDarker,

  borderColorPrimary: vars.colors.white,
  borderColorPrimaryHover: vars.colors.white,
  borderColorSecondary: vars.colors.black,
  borderColorSecondaryHover: vars.colors.black,
  borderColorTertiary: vars.colors.green,
  borderColorTertiaryHover: vars.colors.green,
  borderRadiusPrimary: 5,

  fontPrimary: vars.fonts.primary,
  fontSecondary: vars.fonts.secondary,

  ...vars.sizes,

  // component variables
  buttonDefaultColor: vars.colors.white,
  buttonDefaultColorHover: vars.colors.black,
  buttonDefaultBackground: "transparent",
  buttonDefaultBackgroundHover: vars.colors.white,
  buttonDefaultBorderColor: vars.colors.white,
  buttonDefaultBorderColorHover: vars.colors.white,

  buttonAlternativeColor: vars.colors.black,
  buttonAlternativeColorHover: vars.colors.white,
  buttonAlternativeBackground: "transparent",
  buttonAlternativeBackgroundHover: vars.colors.black,
  buttonAlternativeBorderColor: vars.colors.black,
  buttonAlternativeBorderColorHover: vars.colors.black,

  buttonGreenColor: vars.colors.green,
  buttonGreenColorHover: vars.colors.white,
  buttonGreenBackground: "transparent",
  buttonGreenBackgroundHover: vars.colors.green,
  buttonGreenBorderColor: vars.colors.green,
  buttonGreenBorderColorHover: vars.colors.green,

  inputBorderBottomColor: "rgba(255,255,255,0.5)",
  inputBorderBottomColorValid: vars.colors.green,
  inputBorderBottomColorFocus: "rgba(255,255,255,1)",
  inputPlaceholderColor: "rgba(255,255,255,0.5)",
  inputColor: "rgba(255,255,255,1)",
  checkboxBorderColor: vars.colors.green,
  checkboxMarkColor: vars.colors.white,

  bodyBackground: utils.gradientLinear(
    "to right",
    vars.gradients.greyDarkToGrey
  ),
  sectionImageTextBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenDarkerToBlueDarker
  ),
  sectionBenefitImages: utils.gradientLinear(
    "135deg",
    vars.gradients.greenLightToBlue
  ),
  sectionMembership: utils.gradientLinear(
    "135deg",
    vars.gradients.greenDarkestToBlueDark
  ),
  sectionImageBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenDarkerToBlueDarker
  ),
  membersBadgeBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenLightToGreenDark
  ),
  membersBadgeBackgroundHover: utils.gradientLinear(
    "135deg",
    vars.gradients.greenLightToBlue
  ),
  popupOfferContentBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenLightToBlue
  ),
  overlayBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenDarkestToBlueDark,
    vars.alphaMaps.fiftyFifty
  ),
  overlayGradientBackground: utils.gradientLinear(
    "180deg",
    vars.gradients.greenDarkestToBlueDarkest,
    vars.alphaMaps.thirtyToNinty
  ),
  overlayRadialGradientBackground: utils.gradientRadial(
    vars.gradients.greenDarkestToBlueDarkest,
    vars.alphaMaps.zeroToNinty
  ),
  magazineBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenDarkerToBlueDarker
  ),
  sectionFormsBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenDarkerToBlueDarker
  ),

  sectionTextBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenDarkestToBlueDark
  ),
  sectionFormsButtonBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenLightToGreenDark
  ),

  magazineNavigationItemColor: vars.colors.green,
  magazineNavigationItemFirstColor: vars.colors.greyLightest,
  magazineItemContentTextBackground: utils.gradientLinear(
    "180deg",
    vars.gradients.whiteTriple,
    vars.alphaMaps.zeroHundredHundred,
    vars.positionMaps.zeroEightyHundred
  ),
  magazineItemDateColor: vars.colors.greyLightest,
  magazineItemCategoryStripeBackground: vars.colors.green,

  imageStripeBackground: vars.colors.green,
  imageBoxShadow: "5px 5px 10px rgba(0,0,0,0.3)",

  linkColor: vars.colors.white,
  linkColorHover: vars.colors.white,
  linkBorderColor: "transparent",
  linkBorderColorHover: vars.colors.green,
  linkBackgroundColor: "transparent",
  linkBackgroundColorHover: "transparent",

  scrollBarThumb: "#333333",
  scrollBarTrack: "#cccccc",
  scrollBarWidth: "2px",

  gridSpaceGutter: vars.sizes.spaceDoubleEight,
  sizeHeaderHeight: 120,
  sizeFooterHeight: 70,
};
