import React from 'react';
import PropTypes from 'prop-types';
import { LinkStyled } from './styles';

const SiteSwitch = props => {
  const { alternateEntries } = props;

  if (alternateEntries.length === 0) {
    return null;
  }

  // Display link with fullUri for every alternative site
  return alternateEntries.map(entry => (
    <LinkStyled key={`${entry.id}-${entry.site.id}`} to={entry.fullUri || '/'}>
      {entry.site.handle}
    </LinkStyled>
  ));
};

SiteSwitch.propTypes = {
  alternateEntries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      fullUri: PropTypes.string,
      site: PropTypes.shape({
        id: PropTypes.number,
        handle: PropTypes.string,
      }),
    }),
  ),
};

SiteSwitch.defaultProps = {
  alternateEntries: [],
};

export default SiteSwitch;
