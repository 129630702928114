import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@emotion/react'

import themeDefault from 'components/Layout/themes/default'
import themeAlternative from 'components/Layout/themes/alternative'
import themeTransparent from 'components/Layout/themes/transparent'
import themeGreen from 'components/Layout/themes/green'

export default function SwitchTheme({ name, children }) {
  const themeType = {
    default: themeDefault,
    alternative: themeAlternative,
    transparent: themeTransparent,
    green: themeGreen,
  }

  // Do not set default theme as fallback otherwise the inheritance wont work, simply throw
  // an error and use theming correct instead of polyfilling. So getting errors is actually right
  // and you should provide the correct theme instead. ~DH
  // if (!(name in themeType)) {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.error(`This theme does not exist!: ${name}`);
  //   }
  // }

  // Anyway we need to push an empty object to let e.g. a button component within the cms create an 'undefined'
  // field which lets react use the '<Button>' component in default mode choosing the right theming out of
  // the box. ~DH
  const theme = name in themeType ? themeType[name] : {}

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

SwitchTheme.propTypes = {
  name: PropTypes.string,
}

SwitchTheme.defaultProps = {
  name: 'default',
}
