import React from 'react';
import ListItem from 'components/List';

import { styled } from 'utils/emotion';
import { toPx } from 'utils/strings';

const RedactorListStyled = styled(ListItem)(props => ({
  margin: `${toPx(props.theme.spaceEight)} 0`,
}));

export default function RedactorLink({ children, element }) {
  return <RedactorListStyled element={element}>{children}</RedactorListStyled>;
}
