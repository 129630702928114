const vars = require("components/Layout/variables");
const utils = require("utils/styles");

module.exports = {
  name: "green",
  inherit: "default",

  // colorPrimary: INHERIT,
  // colorPrimaryHover: INHERIT
  // colorSecondary: INHERIT
  // colorSecondaryHover: INHERIT
  // colorTertiary: INHERIT
  // colorTertiaryHover: INHERIT,

  background: vars.colors.green,
  // backgroundPrimary: INHERIT,
  // backgroundPrimaryHover: INHERIT
  backgroundSecondary: vars.colors.green,
  backgroundSecondaryHover: vars.colors.green,
  backgroundTertiary: vars.colors.green,
  backgroundTertiaryHover: vars.colors.green,

  backgroundInstagram: vars.colors.green,

  borderColorPrimary: vars.colors.green,
  // borderColorPrimaryHover: INHERIT,
  // borderColorSecondary: INHERIT,
  borderColorSecondaryHover: vars.colors.green,
  borderColorTertiary: vars.colors.green,
  borderColorTertiaryHover: vars.colors.green,

  // fontPrimary: INHERIT,
  // fontSecondary: INHERIT,

  // ...vars.sizes, INHERIT

  // component variables

  buttonGreenColor: vars.colors.green,
  buttonGreenColorHover: vars.colors.white,
  buttonGreenBackground: vars.colors.white,
  buttonGreenBackgroundHover: vars.colors.green,
  buttonGreenBorderColor: vars.colors.green,
  buttonGreenBorderColorHover: vars.colors.white,

  inputBorderBottomColorValid: "#017d7a",
  inputPlaceholderColor: "rgba(255,255,255,0.5)",
  checkboxBorderColor: vars.colors.white,

  overlayBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenLightToGreenDark
  ),

  sectionTextBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenLightToGreenDark
  ),

  sectionBenefitImages: utils.gradientLinear(
    "135deg",
    vars.gradients.greenDarkerToBlueDarker
  ),

  sectionImageBackground: utils.gradientLinear(
    "135deg",
    vars.gradients.greenLightToGreenDark
  )
};
