import React from "react";
import PropTypes from "prop-types";

import { styled } from "utils/emotion";

const StyledP = styled("p")(
  {
    letterSpacing: "0.8px",
    fontSize: "17px",
    fontWeight: "normal",
    fontStyle: "normal",
    textTransform: "none",
    lineHeight: "32px"
  },
  props => ({
    color: props.theme.colorPrimary,
    margin: props.margin,
    padding: props.padding,
    textAlign: props.textAlign
  })
);

const StyledSpan = styled("span")({});

export default function Text({
  element,
  children,
  margin,
  padding,
  textAlign,
  className
}) {
  const ComponentList = {
    p: StyledP,
    span: StyledSpan,
    div: StyledSpan
  };
  const Component = ComponentList[element];

  return (
    <Component
      margin={margin}
      textAlign={textAlign}
      padding={padding}
      className={className}
    >
      {children}
    </Component>
  );
}

Text.propTypes = {
  element: PropTypes.string
};

Text.defaultProps = {
  element: "p"
};
