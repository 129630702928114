import React from 'react';
import PropTypes from 'prop-types';
import { toPercent } from 'utils/math';

import { GridContext } from 'components/Grid';

import { GridItemStyled } from './GridItemStyled';

export default function GridItem(props) {
  const {
    children,
    display,
    height,
    first,
    last,
    mobile,
    tablet,
    tabletFluid,
    laptop,
    desktop,
    top,
    middle,
    bottom,
    background,
    textAlign,
    zIndex,
    className,
    ...rest
  } = props;

  const order = {
    first: '1',
    last: '-1',
  };

  const percentMobile = toPercent(mobile);
  const percentTablet = toPercent(tablet);
  const percentTabletFluid = toPercent(tabletFluid);
  const percentLaptop = toPercent(laptop);
  const percentDesktop = toPercent(desktop);

  return (
    <GridContext.Consumer>
      {({ gutter }) => (
        <GridItemStyled
          className={className}
          display={display}
          gutter={gutter}
          height={height}
          textAlign={textAlign}
          background={background}
          zIndex={zIndex}
          order={order[(first && 'first') || (last && 'last')]}
          mobile={percentMobile}
          tablet={percentTablet}
          tabletFluid={percentTabletFluid}
          laptop={percentLaptop}
          desktop={percentDesktop}
          {...rest}
        >
          {children}
        </GridItemStyled>
      )}
    </GridContext.Consumer>
  );
}

GridItem.propTypes = {
  mobile: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
  zIndex: PropTypes.string,
  display: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  middle: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  bottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

GridItem.defaultProps = {
  mobile: 1,
  first: false,
  last: false,
  zIndex: null,
  display: null,
  top: null,
  middle: null,
  bottom: null,
};
