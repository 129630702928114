import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

export default function Link({
  children,
  display,
  target,
  className,
  activeClassName,
  rel,
  to,
  style,
  onClick,
}) {
  // Check if the link points to a file.
  function isFile(url) {
    if (!url) return false;

    const filename = url.split("/").pop();
    return /.+\..+/.test(filename);
  }

  function adjustBackendUrl(url) {
    // Skip adjustment if cms url isn't included.
    if (
      !url ||
      typeof url !== "string" ||
      url.indexOf(process.env.GATSBY_URL_BASE) === -1
    ) {
      return isFile(url) ? url : `${url}`;
    }

    // If it's a normal cms url, remove the cms link, so it's seen as internal link.
    return isFile(url)
      ? url
      : `/${url.replace(process.env.GATSBY_URL_BASE, "")}`.replace("//", "/");
  }

  // Adjust urls from backend.
  const toStripped = adjustBackendUrl(to);
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(toStripped);

  if (internal) {
    return (
      <GatsbyLink
        to={toStripped}
        className={className}
        activeClassName={activeClassName}
        display={display}
        target={target}
        rel={rel}
        style={style ? { ...style } : null}
        onClick={onClick}
      >
        {children}
      </GatsbyLink>
    );
  }

  // If its not a link
  if (!to) {
    console.warn("Invalid target for a link on this page.");
    return null;
  }

  // If its an external link
  return (
    <OutboundLink
      href={toStripped}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      display={display}
      style={style ? { ...style } : null}
    >
      {children}
    </OutboundLink>
  );
}
