import React from 'react';
import SwitchTheme from 'components/SwitchTheme';

export default function Blockquote({ theme, children }) {
  return (
    <SwitchTheme name={theme}>
      <>{children}</>
    </SwitchTheme>
  );
}
