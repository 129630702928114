import React from "react"

import Image from "components/Image"
import Overlay from "components/Overlay"

import { ImageWrapperStyled } from "./styles"

export default function NavigationBgImage({ items, currentItemId }) {
  const getCurrentItemIdOrFallbackId = (currentItemId) => {
    const id =
      items.find((item) => item.id === currentItemId)?.id || items[0].id
    return id
  }

  return (
    <div>
      {items &&
        items.map((item, i) => (
          <ImageWrapperStyled
            active={item.id === getCurrentItemIdOrFallbackId(currentItemId)}
            key={item.id}
          >
            {item.navMainBgImage?.[0] && (
              <Image
                fluid={item.navMainBgImage?.[0]}
                args={{ maxWidth: 1920 }}
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                }}
              />
            )}
          </ImageWrapperStyled>
        ))}
      <Overlay />
    </div>
  )
}
