import React from 'react'
import { withTheme } from '@emotion/react'
import PropTypes from 'prop-types'
import ImageGatsbyCraft from 'components/ImageGatsbyCraft'
import { styled } from '../../utils/emotion'
import SwitchTheme from '../SwitchTheme'

const getWrapperStyle = ({ boxShadow, hasBoxShadow, theme, style }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '100%',
  boxShadow: boxShadow || (hasBoxShadow && theme.imageBoxShadow),
  ...style,
})

const enhance = withTheme

const ImageSignatureText = styled('div')(
  {
    fontSize: '.9rem',
  },
  (props) => ({})
)

const ImageSignatureDecore = styled('div')(
  {
    height: '1px',
    width: '100px',
    marginTop: '15px',
    marginBottom: '48px',
  },
  (props) => {
    const { theme } = props
    return {
      backgroundColor: theme?.imageStripeBackground
        ? theme.imageStripeBackground
        : '#02b0ab',
    }
  }
)

const ImageSignature = ({ signature }) => {
  return signature ? (
    <>
      <ImageSignatureText>{signature}</ImageSignatureText>
      <SwitchTheme name="default">
        <ImageSignatureDecore />
      </SwitchTheme>
    </>
  ) : null
}

function Image(props) {
  const {
    className,
    imageProps = {},
    imageSignature,
    fluid,
    aspectRatio,
    ...rest
  } = props

  return (
    <>
      <ImageGatsbyCraft
        fluid={fluid}
        className={className}
        style={getWrapperStyle(props)}
        aspectRatio={aspectRatio}
        imgStyle={{
          ...imageProps.style,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          verticalAlign: 'bottom',
          border: 0,
        }}
        {...rest}
      />
      <ImageSignature signature={imageSignature} />
    </>
  )
}

export default enhance(Image)

Image.propTypes = {
  aspectRatio: PropTypes.number,
  boxShadow: PropTypes.string,
  hasBoxShadow: PropTypes.bool,
}

Image.defaultProps = {
  aspectRatio: 1,
  boxShadow: undefined,
  hasBoxShadow: false,
}
