import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'utils/emotion';

import config from '~/site.config.js';
import media from 'utils/mediaqueries';

import { toPx } from 'utils/strings';

import NullWrap from 'components/NullWrap';

const wrapSizes = config?.responsive?.wrapSizes;

const StyledWrapGrid = styled('div')(
  {
    position: 'relative',
  },
  props => ({
    display: props.display,
    margin: props.margin,
    padding: props.padding,
    height: props.height,
    flex: props.flex,
    width: props.width || `calc(${toPx(wrapSizes.mobile)} - ${toPx(props.gutter)})`,
    [media('tablet')]: {
      width: props.width || `calc(${toPx(wrapSizes.tablet)} - ${toPx(props.gutter)})`,
    },
    [media('tabletFluid')]: {
      width: props.width || `calc(${toPx(wrapSizes.tabletFluid)} - ${toPx(props.gutter)})`,
    },
    [media('laptop')]: {
      width: props.width || `calc(${toPx(wrapSizes.laptop)} - ${toPx(props.gutter)})`,
    },
    [media('desktop')]: {
      width: props.width || `calc(${toPx(wrapSizes.desktop)} - ${toPx(props.gutter)})`,
    },
  }),
);

export default function WrapGrid({
  children,
  margin,
  padding,
  height,
  width,
  isNested,
  display,
  flex,
  gutter,
}) {
  const WrapComponent = isNested ? NullWrap : StyledWrapGrid;
  return (
    <WrapComponent
      gutter={gutter}
      margin={margin}
      padding={padding}
      height={height}
      display={display}
      width={width}
      flex={flex}
      isGlobalGridWrapper={!isNested}
    >
      {children}
    </WrapComponent>
  );
}

WrapGrid.propTypes = {
  margin: PropTypes.string,
  gutter: PropTypes.number,
};

WrapGrid.defaultProps = {
  margin: '0 auto',
  gutter: 40,
};
